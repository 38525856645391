<div class="row gap-2 align-items-end mt-2 mb-4">
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.TOTAL_PRODUCT_PRICE" | translate }} ({{ paymentInfo.totalQuantity }} {{ "PAYMENT.ITEM" |
            translate }})</p>
        <p data-cy="totalProductPrice">
            {{ paymentInfo.totalAmount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div *ngIf="paymentInfo.promotionDiscount" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p>
            {{ paymentInfo.promotionDiscount| currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div *ngIf="paymentInfo.couponDiscount" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.COUPON_DISCOUNT" | translate }}</p>
        <p>
            {{ paymentInfo.couponDiscount| currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.DELIVERY_FEE" | translate }}</p>
        <p>
            {{ paymentInfo.shippingCost | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div class="d-flex justify-content-between fw-semibold">
        <p>{{ "PAYMENT.TOTAL" | translate }}</p>
        <p data-cy="totalPrice">
            {{ paymentInfo.netAmount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
</div>