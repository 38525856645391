<div class="aura-container-custom">
    <div id="header-page" class="row header-pane">
        <div class="col">
            <h2>
                &nbsp;&nbsp;&nbsp;<i data-cy="button-close-header-page" class="fa-solid fa-xmark"
                    aria-hidden="true" (click)="navigateToOrdersDetail()" type="button"></i>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span data-cy="label-header-page">ไม่อนุมัติคำขอยกเลิกคำสั่งซื้อ</span>
            </h2>
        </div>
    </div>
    <div id="body" class="row body-pane">
        <div class="row">
            <div class="col order-label">
                <span data-cy="label-stockist-order-number">
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p data-cy="description">คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย
                    คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย คำอธิบาย </p>
            </div>
        </div>
    </div>
    <div id="footer-page" class="row footer-pane">
        <div class="row">
            <div class="col">
                <button data-cy="button-goto-main-page" type="button" class="btn btn-block back-to-home-btn" (click)="navigateToOrders()">
                    กลับหน้าหลัก
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button data-cy="button-close-footer-page" type="button" class="btn btn-block close-btn" (click)="navigateToOrdersDetail()">
                    ปิด
                </button>
            </div>
        </div>
    </div>
</div>