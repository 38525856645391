import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-language-page',
    templateUrl: './language-page.component.html',
    styleUrls: ['./language-page.component.scss'],
})
export class LanguagePageComponent implements OnInit {
    currentLang!: string;
    constructor(
        private translate: TranslateService,
        private route: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
    }

    goBack(): void {
        window.history.back();
        setTimeout(() => {
            window.location.reload()
        },100)
    }

    switchLanguage(lang: string) {
        this.translate.use(lang);
        this.currentLang = lang;
        window.localStorage.setItem('local', lang);
        this.cdr.detectChanges();
    }
}
