import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-stockist-order-info-confirmed-message',
    templateUrl: './order-info-confirmed-message.component.html',
    styleUrls: ['./order-info-confirmed-message.component.scss'],
})
export class OrderInfoConfirmedMessageComponent implements OnInit {
    constructor(private activeRoute: ActivatedRoute, private route: Router) {}

    orderNumber = '';
    oldOrderNumber: string | undefined = undefined;

    ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.orderNumber = params['order-number'];
        });
        const oldOrderNumber =
            this.activeRoute.snapshot.queryParamMap.get('oldOrderNumber');
        this.oldOrderNumber = oldOrderNumber ? oldOrderNumber : undefined;
    }

    navigateToOrders() {
        this.route.navigate(['/stockist/orders']);
    }

    navigateToOrderDetail() {
        this.route.navigate([
            '/stockist/orders',
            this.orderNumber,
        ]);
    }

    displayHead(): string {
        return this.oldOrderNumber
            ? `หมายเลขคำสั่งซื้อ ${this.orderNumber} (แทนที่ ${this.oldOrderNumber})`
            : `หมายเลขคำสั่งซื้อ ${this.orderNumber}`;
    }
}
