<div class="ml-2 pt-2 pb-2">
    <div class="d-flex">
        <img class="map-icon" [src]="walletIcon" alt="wallet-icon" />
        <div class="d-flex flex-column w-100">
            <div class="text-header fs-sm">{{ "PAYMENT_TO_ADDRESS.BILLING_ADDRESS" | translate }}</div>
            <div class="fs-sm fw-normal text-address">
                {{stockistOrder.billingAddress}}
                {{stockistOrder.billingSubdistrict}} {{stockistOrder.billingDistrict}}
                {{stockistOrder.billingProvince}} {{stockistOrder.billingPostCode}}
            </div>
        </div>
    </div>
</div>