<div class="ml-2 pb-2 pt-2">
    <div class="d-flex">
        <i class="topic fa-solid fa-calendar" style="color: #93f9bc;"></i>
        <div class="d-flex flex-column w-100">
            <div class="text-header fs-sm d-flex justify-content-between align-items-start">
                <div>{{ "DELIVERED_DATE.EXPECTED_DELIVERY" | translate }}</div>
            </div>
            <div class="fs-6 fw-normal text-name">
                {{ deliveredDate | displayDeliveryDate}},  {{this.formatTime(officeTime)}}
            </div>
        </div>
    </div>
    <div class="d-flex align-items-baseline" *ngIf="checkLateDelivered()">
        <i class="fa-solid fa-circle-exclamation mt-1" style="color: #F43A29;"></i>
        <label class="fs-sm text-name" style="color: #F43A29;">{{ "DELIVERED_DATE.VALIDATE" | translate }}</label>
    </div>
</div>
   
