import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategory } from '@core/models/stockist/category.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface Pagination{
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  basePath = `${environment.apiProductUrl}`;
  constructor(private http: HttpClient) {}

  getCategoryLevel1(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(
      `${this.basePath}/category`
    );
  }
}
