import { Component, OnInit } from '@angular/core';
import { Profile } from '@core/models/stockist/profile.model';
import { ProfileService } from '@core/services/stockist/profile.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) { }
  stockistProfile?: Profile;
  linkedLine = false
  modalRef?: NgbModalRef
  currentLang! : string

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.profileService.getProfile().subscribe({
      next: (res) => {
        this.stockistProfile = res
        this.linkedLine = res.verifyLineAccount
      }, error: (err) => { console.error(err) }
    }
    )
  }

  goBack(): void {
    window.history.back();
    setTimeout(() => {
        window.location.reload()
    },100)
  }
  getAddress() {
    return this.stockistProfile?.address + " " + this.translate.instant('PROFILE.SUBDISTRICT') + " " + ( this.currentLang === 'th' ? this.stockistProfile?.subDistrict : this.stockistProfile?.subDistrictEN ) + ", " + this.translate.instant('PROFILE.DISTRICT') + " " + ( this.currentLang === 'th' ? this.stockistProfile?.district : this.stockistProfile?.districtEN ) + ", " + ( this.currentLang === 'th' ? this.stockistProfile?.province : this.stockistProfile?.provinceEN ) + " " + this.stockistProfile?.postCode
  }
  onClickLinkLine() {
    this.profileService.getLinkAuth().subscribe({
      next: (res) => {
        window.location.href = res.url;
      }, error: (err) => { console.error(err) }
    })
  }
  async onClickUnlinkLine() {
    const result = await this.openUnLinkModal()
    if (result) {
      this.profileService.unlinkLine().subscribe({
        next: () => {
          window.location.reload()
        }, error: (err) => { console.error(err) }
      })
    }
  }

  async openUnLinkModal() {
    this.modalRef = this.modalService.open(ModalMessageComponent);
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Success,
      title: this.translate.instant('PROFILE.TITLE'),
      message: this.translate.instant('PROFILE.MESSAGE'),
      confirmLabel: this.translate.instant('PROFILE.CONFIRM'),
      cancelLabel: this.translate.instant('PROFILE.CANCEL'),
    };
    const confirm: boolean = await this.modalRef.result;
    return confirm;
  }
}
