import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-successful-order-cancellation',
  templateUrl: './successful-order-cancellation.component.html',
  styleUrls: ['./successful-order-cancellation.component.scss']
})
export class SuccessfulOrderCancellationComponent implements OnInit {
  constructor(private activeRoute: ActivatedRoute, private router: Router) {}

  orderNumber = '';

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.orderNumber = params['order-number'];
    });
  }
  navigateToOrdersDetail() {
    this.router.navigate(['/stockist/orders', this.orderNumber]);
  }
  navigateToOrders() {
    this.router.navigate(['/stockist/orders']);
  }
}
