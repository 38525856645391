import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@core/models/shares/http.model';
import { ProductParams } from '@core/models/stockist/product.model';
import { CreateStockistProductPayload as CreateUpdateStockistProductPayload, StockistProduct, StockistProductCountResponse, StockistProductDetail } from '@core/models/stockist/stockist-product-shelf';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductShelfService {
    baseURL = `${environment.apiUrl}/api/product/stockist`;

    constructor(private http: HttpClient) { }

    getStockistProductList(payload: ProductParams): Observable<APIResponse<StockistProduct[]>>{
        return this.http.post<APIResponse<StockistProduct[]>>(this.baseURL + '/products', payload)
    }

    getStockistproductDetail(id: number): Observable<StockistProductDetail>{
        return this.http.get<StockistProductDetail>(this.baseURL + '/product/'+id)
    }

    createStockistProduct(payload: CreateUpdateStockistProductPayload): Observable<StockistProductDetail> {
        return this.http.post<StockistProductDetail>(this.baseURL + '/product', payload)
    }

    updateStckistProductById(id: number, payload: CreateUpdateStockistProductPayload): Observable<StockistProductDetail> {
        return this.http.patch<StockistProductDetail>(this.baseURL + '/product/'+id, payload)
    }

    getStockistProductCount(): Observable<StockistProductCountResponse> {
        return this.http.get<StockistProductCountResponse>(this.baseURL + '/product/count')
    }
}
