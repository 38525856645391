import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { APIResponse } from "@core/models/shares/http.model";
import { StockistProfile } from "@core/models/stockist/stockist.model";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseURL = `${environment.apiUrl}/api/customer/stockist`;

  constructor(private http: HttpClient) {}

  getStockistProfile(): Observable<StockistProfile>  {
    return this.http.get<StockistProfile>(`${this.baseURL}/profile`);
  }

  validateDuplicatePhoneNumber(
    phoneNumber: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `http://localhost:5240/duplicate-phone?phoneNumber=${phoneNumber}`
    );
  }
}
