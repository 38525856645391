<div id="myModal" class="modal">
    <div id="animate" class="modal-content">
        <div class="header">
            <span class="">{{ 'FILTER.FILTER' | translate}}</span>
            <i class="fa-solid fa-xmark" (click)="close()"></i>
        </div>
        <div class="content-container mt-3">
            <div *ngFor="let field of [
                  { name: categoryOption, text: category, id: 'category' },
                  { name: brandOption, text: brand, id: 'brand' },
                ]">
                <div class="accordion mb-3" [id]="field.id + 'Accordion'">
                    <div class="accordion-item">
                        <h2 class="accordion-header" [id]="'heading' + field.id">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapse' + field.id" aria-expanded="true"
                                [attr.aria-controls]="'collapse' + field.id">
                                <div class="d-flex align-content-center">
                                    <span class="pt-1">{{ field.text }} </span>
                                    <span class="number-circle" *ngIf="showSelectedNumber(field.id)">
                                        {{ showSelectedNumber(field.id) }}
                                    </span>
                                </div>
                            </button>
                        </h2>
                        <div [id]="'collapse' + field.id" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'heading' + field.id"
                            [attr.data-bs-parent]="'#' + field.id + 'Accordion'">
                            <div class="accordion-body">
                                <div class="d-flex justify-content-between option"
                                    *ngFor="let option of field.name; let i = index">
                                    <label [for]="option.name + i" *ngIf="field.id !== 'stockist'">{{ local === 'th' ? option.name : option.nameEN }}</label>
                                    <label [for]="option.name + i" *ngIf="field.id === 'stockist'">{{ option.name }}</label>
                                    <input (change)="onInputChecked(option.id,field.id, $event)" type="checkbox"
                                        [id]="option.name + i" class="custom-checkbox"
                                        [checked]="isChecked(option.id, field.id)">
                                    <label [for]="option.name + i"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion" id="filterByAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFilterBy">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFilterBy" aria-expanded="false" aria-controls="collapseBrand">
                            {{ 'FILTER.SORT_BY' | translate}}
                        </button>
                    </h2>
                    <div id="collapseFilterBy" class="accordion-collapse collapse" aria-labelledby="headingFilterBy"
                        data-bs-parent="#filterByAccordion">
                        <div class="accordion-body">
                            <div class="d-flex justify-content-between option"
                                *ngFor="let option of filterByOption; let i = index">
                                <label [for]="option.name + i">{{option.name}}</label>
                                <input type="radio" [(ngModel)]="orderBy" [id]="option.name + i" name="filterBy"
                                    [value]="option.value" class="custom-radio">
                                <label [for]="option.name + i"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="footer-container fixed-bottom p-3">
                <button class="clear-button" (click)="resetFilterValue()">
                  {{ 'ACTION.CLEAR' | translate}}
                </button>
                <button class="confirm-button" (click)="submit()">
                  {{ 'ACTION.CONFIRM' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
