<div style="padding-top: 1.5rem;">
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigateToOrders()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_HISTORY.GO_BACK" | translate }}</label>
        </div>
    </nav>
    <!-- status bar -->
    <div class="aura-container-custom">
        <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21c063" type="ball-clip-rotate" [fullScreen]="true"
            name="spinner" style="margin-top: 50px !important;"></ngx-spinner>
        <div class="buttons-container" style="overflow-x: auto;">
            <button *ngFor="let status of orderStatuses" [class.active]="isActive(status)" (click)="setStatus(status)">
                {{ getStatusTranslation(status, 'th') }}
            </button>
        </div>
    </div>

    <div class="data-content" *ngIf="activeStatus === 'Cancelled' && !loading_data">
        <div class="tab-section">
            <div class="tab-item" [ngClass]="{ actived: status.value === tabSelected }"
                *ngFor="let status of cancel_statuses" (click)="onChangeTab(status.value)">
                <label for="status-name">{{ status.name }}</label>
            </div>
        </div>
    </div>

    <div *ngIf="haveData && !loading_data" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <div *ngFor="let status of [
        { name: ('ORDER_HISTORY.TOTAL_ORDER_PENDING' | translate), orders: pending , badgeColor : '#C4CFFE', fontColor : '#4A57A5',page : 1, text: 'Pending'},
        { name: ('ORDER_HISTORY.TOTAL_ORDER_CONFIRMED' | translate), orders: confirmed  , badgeColor : '#A6EBEB', fontColor : '#238484',page : 2, text: 'Confirmed'},
        { name: ('ORDER_HISTORY.TOTAL_ORDER_CANCEL_REQUESTED' | translate), orders: cancelrequested  , badgeColor : '#FFDDBD', fontColor : '#ED7201',page : 3, text: 'CancelRequested'},
        { name: ('ORDER_HISTORY.TOTAL_ORDER_CANCELLED' | translate), orders: cancelled  , badgeColor : '#FFAEAE', fontColor : '#C30000',page : 4, text: 'Cancelled'},
        { name: ('ORDER_HISTORY.TOTAL_ORDER_DELIVERED' | translate), orders: delivered  , badgeColor : '#93F9BC', fontColor : '#139A4B',page : 5, text: 'Delivered'},
        { name: ('ORDER_HISTORY.TOTAL_ORDER' | translate), orders: all  , badgeColor : '#DBDBDB', fontColor : '#5A5A5A',page : 5, text: 'All'}
        ]; let i = index">
            <ng-container *ngIf="activeStatus === status.text || activeStatus === 'ALL'">
                <!-- แถมสรุปจำนวนคำสั่งซื้อของสถานะต่างๆ -->
                <span class="d-flex head mt-3 mx-3" *ngIf="activeStatus !== 'ALL'">{{status.name}} : &nbsp;
                    <div class="totalBadge px-2" [style.background-color]="status.badgeColor"
                        [style.color]="status.fontColor">
                        <svg class="mb-1 mt-1" width="13" height="13" viewBox="0 0 13 13" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.33301 0C1.22844 0 0.333008 0.895431 0.333008 2V3.16667C0.333008 3.6269 0.706104 4 1.16634 4H2.33301V10.6667C2.33301 11.7712 3.22844 12.6667 4.33301 12.6667H10.9997C12.1042 12.6667 12.9997 11.7712 12.9997 10.6667V9.5C12.9997 9.03976 12.6266 8.66667 12.1663 8.66667H11.6663V2C11.6663 0.89543 10.7709 0 9.66634 0H2.33301ZM2.33301 1.33333C1.96482 1.33333 1.66634 1.63181 1.66634 2V2.66667H2.33301V1.33333ZM5.55257 11.3333H10.9997C11.3679 11.3333 11.6664 11.0349 11.6664 10.6667V10H5.66638V10.6667C5.66638 10.9004 5.62627 11.1248 5.55257 11.3333ZM5.66634 3.33333C5.29815 3.33333 4.99967 3.63181 4.99967 4C4.99967 4.36819 5.29815 4.66667 5.66634 4.66667H8.33301C8.7012 4.66667 8.99967 4.36819 8.99967 4C8.99967 3.63181 8.7012 3.33333 8.33301 3.33333H5.66634ZM4.99967 6.66667C4.99967 6.29848 5.29815 6 5.66634 6H6.99967C7.36786 6 7.66634 6.29848 7.66634 6.66667C7.66634 7.03486 7.36786 7.33333 6.99967 7.33333H5.66634C5.29815 7.33333 4.99967 7.03486 4.99967 6.66667Z"
                                [attr.fill]="status.fontColor" />
                        </svg>
                        <span class="ms-1 mb-1">{{totalOrder}}</span>
                    </div>
                </span>

                <ng-container *ngIf="status.text !== 'ALL' && activeStatus !== 'ALL'">
                    <ng-container *ngFor="let order of status.orders">
                        <div (click)="navigateToOrder(order)" class="d-flex flex-column mt-3 mx-3 orderList"
                            aria-hidden="true">

                            <div class="order-card">
                                <div class="d-felx">
                                    <div class="card-topic">
                                        <div class="retailer-name-icon">
                                            <img src="../../../../../../../assets/icons/shop.svg" />
                                            <p class="order-retail-name">
                                                {{ order.retailerName }}
                                            </p>
                                        </div>
                                        <p class="font-black total-price">
                                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                                        </p>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between order-id-date">
                                    <p class="order-id-font">
                                        {{ "ORDER_HISTORY.ORDER_ID" | translate}} {{ order.id }}
                                    </p>
                                    <!-- <p class="order-time">{{ order.createdAt | displayDate }}</p> -->
                                </div>
                                <div class="d-flex justify-content-between ">
                                    <div class="test">
                                        <p class="order-date-font">
                                            {{ "ORDER_HISTORY.ORDER_AT" | translate}} {{ order.createdAt | displayDate }}
                                        </p>
                                    </div>
                                    <div class="test">
                                        <p class="order-date-font pending" *ngIf="order.status === 'Pending'">
                                            {{ "ORDER_HISTORY.STATUS_PENDING" | translate}}
                                        </p>
                                        <p class="order-date-font confirmed" *ngIf="order.status === 'Confirmed'">
                                            {{ "ORDER_HISTORY.STATUS_CONFIRMED" | translate}}
                                        </p>
                                        <p class="order-date-font cancel-requested"
                                            *ngIf="order.status === 'CancelRequested'">
                                            {{ "ORDER_HISTORY.STATUS_CANCEL_REQUESTED" | translate}}
                                        </p>
                                        <p class="order-date-font cancelled"
                                            *ngIf="order.status === 'Cancelled' && order.actionBy === 'Retailer'">
                                            {{ "ORDER_HISTORY.STATUS_CANCEL_BY_RETAILER" | translate}}
                                        </p>

                                        <p class="order-date-font cancelled"
                                            *ngIf="order.status === 'Cancelled' && order.actionBy === 'Stockist'">
                                            {{ "ORDER_HISTORY.STATUS_CANCEL_BY_STOCKIST" | translate}}
                                        </p>
                                        <p class="order-date-font delivered" *ngIf="order.status === 'Delivered'">
                                            {{ "ORDER_HISTORY.STATUS_DELIVERED" | translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex order-quantity-fee-day">
                                <div class="test">
                                    <div class="d-flex">
                                        <div>
                                            <img src="../../../../../../../assets/icons/product.svg" />
                                        </div>
                                        <p>{{ "ORDER_HISTORY.PRODUCT_QUANTITY" | translate}}</p>
                                    </div>
                                    <span>{{order.totalQuantity}}</span>
                                    <span> {{ "ORDER_HISTORY.ITEM" | translate}}</span>
                                </div>
                                <div class="test">
                                    <div class="d-flex">
                                        <i class="fa-solid fa-truck" style="color: #F43A29;"></i>
                                        <p>{{ "ORDER_HISTORY.DELIVERY_FEE" | translate}}</p>
                                    </div>
                                    <span>฿{{order.deliveryFee}}</span>
                                </div>
                                <div class="test">
                                    <div class="d-flex ">
                                        <i class="fa-solid fa-calendar" style="color: #FFC130;"></i>
                                        <p>{{ "ORDER_HISTORY.DELIVERED_DATE" | translate}}</p>
                                    </div>
                                    <div class="open-day">
                                        <div *ngFor="let day of days">
                                            <span
                                                [ngStyle]="{'color': isDayActive(day, order.officeDays) ? colors[1] : 'black'}">{{
                                                day
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="status.text === 'ALL' && activeStatus === 'ALL'">
                    <ng-container *ngFor="let order of status.orders">
                        <div (click)="navigateToOrder(order)" class="d-flex flex-column mt-3 mx-3 orderList"
                            aria-hidden="true">
                            <div class="order-card">
                                <div class="d-felx">
                                    <div class="card-topic">
                                        <div class="retailer-name-icon">
                                            <img src="../../../../../../../assets/icons/shop.svg" />
                                            <p class="order-retail-name">
                                                {{ order.retailerName }}
                                            </p>
                                        </div>
                                        <p class="font-black total-price">
                                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                                        </p>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between order-id-date">
                                    <p class="order-id-font">
                                        {{ "ORDER_HISTORY.ORDER_ID" | translate}} {{ order.id }}
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between ">
                                    <div class="test">
                                        <p class="order-date-font">
                                            {{ "ORDER_HISTORY.ORDER_AT" | translate}} {{ order.createdAt | displayDate }}
                                        </p>
                                    </div>
                                    <div class="test">
                                        <p class="order-date-font pending" *ngIf="order.status === 'Pending'">
                                            {{ "ORDER_HISTORY.STATUS_PENDING" | translate}}
                                        </p>
                                        <p class="order-date-font confirmed" *ngIf="order.status === 'Confirmed'">
                                            {{ "ORDER_HISTORY.STATUS_CONFIRMED" | translate}}
                                        </p>
                                        <p class="order-date-font cancel-requested"
                                            *ngIf="order.status === 'CancelRequested'">
                                            {{ "ORDER_HISTORY.STATUS_CANCEL_REQUESTED" | translate}}
                                        </p>
                                        <p class="order-date-font cancelled" *ngIf="order.status === 'Cancelled'">
                                            {{ "ORDER_HISTORY.STATUS_CANCELLED" | translate}}
                                        </p>
                                        <p class="order-date-font delivered" *ngIf="order.status === 'Delivered'">
                                            {{ "ORDER_HISTORY.STATUS_DELIVERED" | translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex order-quantity-fee-day">
                                <div class="test">
                                    <div class="d-flex">
                                        <div>
                                            <img src="../../../../../../../assets/icons/product.svg" />
                                        </div>
                                        <p>{{ "ORDER_HISTORY.PRODUCT_QUANTITY" | translate}}</p>
                                    </div>
                                    <span>{{order.totalQuantity}}</span>
                                    <span> {{ "ORDER_HISTORY.ITEM" | translate}}</span>
                                </div>
                                <div class="test">
                                    <div class="d-flex">
                                        <i class="fa-solid fa-truck" style="color: #F43A29;"></i>
                                        <p>{{ "ORDER_HISTORY.DELIVERY_FEE" | translate}}</p>
                                    </div>
                                    <span>฿{{order.deliveryFee}}</span>
                                </div>

                                <div class="test">
                                    <div class="d-flex ">
                                        <i class="fa-solid fa-calendar" style="color: #FFC130;"></i>
                                        <p>{{ "ORDER_HISTORY.DELIVERED_DATE" | translate}}</p>

                                    </div>
                                    <div class="open-day">
                                        <div *ngFor="let day of days">
                                            <span 
                                                [ngStyle]="{
                                                'color': isDayActive(
                                                    day,
                                                    order.officeDays
                                                )
                                                    ? colors[1]
                                                    : 'black'
                                                }">{{ day }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

            </ng-container>
        </div>
    </div>

    <div *ngIf="!haveData && !loading_data" class="empty-order">
        <div class="content">
            <img src="../../../../../../../assets/images/empty-history.png" alt="Empty history image">
            <p>{{ "ORDER_HISTORY.EMPTY_ORDER" | translate}}</p>
        </div>
    </div>
</div>