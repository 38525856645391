import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

const THAI_MONTH_NAMES = [
    "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
];

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

    override format(date: Date, displayFormat: string): string {
        if (displayFormat === 'input') {
            const day = date.getDate().toString().padStart(2, '0');
            const monthIndex = date.getMonth();
            const month = THAI_MONTH_NAMES[monthIndex];
            const year = date.getFullYear() + 543;
            return `${day} ${month} ${year}`;
        }
        return date.toLocaleDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct | null {
        if (value) {
            const parts = value.split(' ');
            const day = parseInt(parts[0], 10);
            const monthIndex = THAI_MONTH_NAMES.indexOf(parts[1]);
            const year = parseInt(parts[2], 10) - 543; 
            return { day, month: monthIndex + 1, year };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date) {
            const day = date.day.toString().padStart(2, '0');
            const month = THAI_MONTH_NAMES[date.month - 1];
            const year = (date.year + 543).toString(); 
            return `${day} ${month} ${year}`;
        }
        return '';
    }
}