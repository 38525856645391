import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentInfo } from "@core/models/shares/modal.model";
import { StockistPurchaseOrderDetail } from "@core/models/stockist/stockist.model";
import { StockistService } from "@core/services/stockist/purchase.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-stockist-order-info-deprecated',
    templateUrl: './order-info-deprecated.component.html',
    styleUrls: ['./order-info-deprecated.component.scss'],
})
export class OrderInfoDeprecatedComponent implements OnInit {

    orderNumber = "";
    @Input() stockistOrder: StockistPurchaseOrderDetail;

    iconMap = 'assets/images/map.png';

    orderIsHavePromotionGift = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private stockistService: StockistService,
        private router: Router,
        private modalService: NgbModal,
    ) {
        this.stockistOrder = new StockistPurchaseOrderDetail({});
    }

    ngOnInit() {
        this.stockistOrder.promotions.map((pr) => {
            pr.promotionType === "Gift"? this.orderIsHavePromotionGift = true : ""
        })
    }

    getTotalQuantity(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }


    getTotalAmount(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getNetAmount(): number {
        if (!this.stockistOrder) return 0
        const netAmount = this.stockistOrder.netAmount;
        return netAmount

    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.stockistOrder.couponDiscount,
            promotionDiscount: this.stockistOrder.promotionDiscount,
            shippingCost: this.stockistOrder?.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    navigateToOrderHistory() {
      window.history.back();
      setTimeout(() => {
          window.location.reload()
      },100)
  }
}
