import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delivered-date',
  templateUrl: './delivered-date.component.html',
  styleUrls: ['./delivered-date.component.scss']
})
export class DeliveredDateComponent {
  @Input() deliveredDate!: string;
  @Input() officeTime!: string;

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('th');
  }
  mapIcon = 'assets/images/map-icon.png'

  checkLateDelivered() {
    return new Date(this.deliveredDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
  }

  formatTime(officeTime: string): string {
    if (officeTime) {
        const [startTime, endTime] = officeTime.split("-");
        const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
        const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
        return `${formattedStartTime} - ${formattedEndTime} น.`;
    }
    return '';

  }
}
