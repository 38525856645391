<div>
    <div class="gift-container">
        <div class="d-flex gap-2 align-items-center">
            <img class="img-fit" alt="Product image" [src]="gift.coverImagePathThump"
                [attr.data-cy]="gift.id" />
            <label class="" [attr.data-cy]="gift.id + '-name'">
                {{ gift.name }}
            </label>
        </div>
        <label [attr.data-cy]="gift.id + '-quantity'">
            {{ quatity }} ชิ้น
        </label>
    </div>
</div>