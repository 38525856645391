import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBrand } from '@core/models/stockist/brand.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface Pagination{
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  basePath = `${environment.apiProductUrl}`;
  constructor(private http: HttpClient) {}

  getBrand(): Observable<IBrand[]> {
    return this.http.get<IBrand[]>(
      `${this.basePath}/brands/all`
    );
  }
}
