<div class="ship-to-address-detail">
    <div class="ml-2">
        <div class="d-flex justify-content-between">
            <div>
                <div class="text-header fs-sm">วันที่จัดส่งสำเร็จ</div>
                <div class="fs-6 fw-normal text-name">
                    {{ deliveryDate | displayDate  }}
                </div>
            </div>
        </div>
    </div>
</div>
