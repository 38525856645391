import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmOrderCancellationFromStatus } from '@core/models/stockist/confirm-order-cancellation.model';
import { StockistService } from '@core/services/stockist/purchase.service';
interface CancellationInfo {
  purchaseOrderNumber: string;
  status: string;
  name: string;
}

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
})

export class CancellationComponent implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    private stockistService: StockistService,
    private route: Router
  ) { }
  reason: string[] = [];
  otherReason = '';
  finalReason = '';
  checkedOtherCheckbox = false;
  isFormValid = false;
  confirmReasonsText = [
    ConfirmOrderCancellationFromStatus.outOfStock,
    ConfirmOrderCancellationFromStatus.wantToChangeProduct,
    ConfirmOrderCancellationFromStatus.wantToChangeAmount,
    ConfirmOrderCancellationFromStatus.cannotDeliver,
    ConfirmOrderCancellationFromStatus.other
  ]
  cancellationInfo: CancellationInfo = {
    purchaseOrderNumber: '',
    status: '',
    name: '',
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.cancellationInfo.purchaseOrderNumber = params['purchaseOrderNumber'];
      this.cancellationInfo.name = params['name'];
    });
  }

  navigateToOrderDetail() {
    this.route.navigate(['/stockist/orders', this.cancellationInfo.purchaseOrderNumber]);
  }

  onClickCheckbox(index: number) {
    this.addReasonToList(index, this.confirmReasonsText);
    this.updateFinalReason();
  }

  addReasonToList(index: number, reasonList: string[]) {
    if (this.reason[index] === undefined || this.reason[index] === null) {
      this.reason[index] = reasonList[index];
      this.isFormValid = true;
    } else {
      delete this.reason[index];
      this.isFormValid = this.reason.some(reason => reason !== undefined);
    }

    this.isClickLastCheckBox(index, reasonList);
  }

  isClickLastCheckBox(index: number, reasonList: string[]) {
    if (index === reasonList.length - 1) {
      this.checkedOtherCheckbox = !this.checkedOtherCheckbox;
    }
  }

  transformReason(): string {
    let result = '';
    this.reason.forEach((item) => {
      result += item;
      result += '|';
    });

    if (this.otherReason) {
      result += this.otherReason;
      result += '|';
    }

    if (this.checkedOtherCheckbox) {
      this.isFormValid = this.otherReason !== ''
    }

    result = result.slice(0, -1);
    return result;
  }

  updateFinalReason() {
    this.finalReason = this.transformReason();
  }

  cancelOrder() {
    // this.updateFinalReason();
    // this.stockistService.cancelOrder(this.cancellationInfo.purchaseOrderNumber, {
    //   reason: this.finalReason
    // })
    //   .subscribe((res) => {
    //     if (res.message === 'success') {
    //       this.route.navigate([`stockist/successful-order-cancellation/${this.cancellationInfo.purchaseOrderNumber}`], {
    //         queryParams: {
    //           orderNumber: this.cancellationInfo.purchaseOrderNumber
    //         },
    //       });
    //     }
    //   });
  }
}