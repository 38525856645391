<div *ngIf="orderDetail">
    <app-stockist-order-info-pending [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Pending'" />
    <app-stockist-order-info-cancel-requested [stockistOrder]="orderDetail"
        *ngIf="orderDetail.status === 'CancelRequested'" />
    <app-stockist-order-info-cancelled [stockistOrder]="orderDetail"
        *ngIf="orderDetail.status === 'Cancelled' || orderDetail.status === 'Deprecated'" />
    <app-stockist-order-info-confirmed [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Confirmed'" />
    <app-stockist-order-info-delivered [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Delivered'" />
    <!-- <app-stockist-order-info-deprecated [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Deprecated'" /> -->
    <!-- <app-stockist-order-info-confirmed-message *ngIf="orderDetail.status === 'CancelRequested'"/> -->
</div>