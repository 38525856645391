<div class="modal-wrapper">
    <div class="modal-content-shelf">
        <div class="modal-header ">
            <h5 class="modal-title">{{ 'SHELF_MANAGEMENT.TITLE' | translate}}</h5>
            <i class="fa-solid fa-xmark close" (click)="close()"></i>
        </div>

        <div class="modal-content-inner ">
            <div class="modal-body">
                <div class="d-flex flex-row product">
                    <div class="form-img">
                        <div class="form-img-inner">
                            <img [src]="data?.product?.coverImagePathThump" alt="assets/media/image_error.jpg"
                                class="image-thumbnail" (error)="handleImageError($event)" />
                        </div>
                    </div>
                    <div class="ml-2" align="left">
                        <a class="description" for="description">EAN: {{data?.product?.ean}}</a><br>
                        <a class="description" for="description">{{data?.product?.name}}</a>
                    </div>
                </div>

                <form [formGroup]="editShelfForm">
                    <div class="form-check form-switch ">
                        <div style=" margin-top: 2px !important;" *ngIf="this.editShelfForm.value.statusCheckbox">
                            {{"LIST_SHELF_MANAGEMENT.ACTIVATE" | translate}}
                        </div>
                        <div style=" margin-top: 2px !important;" *ngIf="!this.editShelfForm.value.statusCheckbox">
                            {{"LIST_SHELF_MANAGEMENT.DEACTIVATE" | translate}}
                        </div>
                        <input data-testid="status" class="form-check-input" type="checkbox" role="switch"
                            id="flexSwitchCheckChecked" data-onstyle="info" formControlName="statusCheckbox"
                        />
                    </div>

                    <div>
                        <app-input-price data-testid="price"
                            placeholder="{{ 'SHELF_MANAGEMENT.UNIT_PRICE' | translate}}"
                            label="{{ 'SHELF_MANAGEMENT.UNIT_PRICE' | translate}}*" formControlName="price"
                            thousandSeparator="," customMask="separator.2" textGroup="฿" [error]="Errors.price">
                        </app-input-price>

                        <div>
                            <app-input-max-min data-testid="minimumUnit" matInput
                                placeholder="{{ 'SHELF_MANAGEMENT.MINIMUM_UNIT' | translate}}"
                                label="{{ 'SHELF_MANAGEMENT.MINIMUM_UNIT' | translate}}*" formControlName="minimumUnit"
                                customMask="0*" [error]="Errors.minimumUnit"
                                ></app-input-max-min>
                        </div>
                    </div>
                </form>

            </div>

            <div id="เพิ่ม disabled ปุ่ม" class="modal-footer">
                <button
                type="button"
                class="btn btn-submit"
                (click)="onSubmit()">
                {{"ACTION.SUBMIT" | translate}}
                </button>
            </div>

        </div>
    </div>
