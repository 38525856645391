import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutStockistComponent } from './layouts/layout-stockist/layout-stockist.component';
import { authGuard } from '@core/guards/auth.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./modules/main/pages/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'stockist',
        component: LayoutStockistComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('@modules/main/pages/stockist/stockist.module').then(
                        (m) => m.StockistModule
                    ),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'stockist',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
