import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StockistPurchaseOrderDetail } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-stockist-order-info-confirmed',
    templateUrl: './order-info-confirmed.component.html',
    styleUrls: ['./order-info-confirmed.component.scss'],
})
export class OrderInfoConfirmedComponent implements OnChanges, OnInit {

    @Input() stockistOrder: StockistPurchaseOrderDetail;

    expandDetails = false;

    iconMap = 'assets/images/map.png';
    walletIcon = 'assets/images/walllet-icon.png'
    detailIcon = 'assets/images/detail-icon.png'
    exclamationIcon = faCircleExclamation;
    openCancelModal = false;

    additionalDetails: { label: string; value: string }[] = [];

    orderIsHavePromotionGift = false
    isLoading = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private stockistService: StockistService,
        private router: Router,
        private modalService: NgbModal,
        private translate: TranslateService,
        private spinner: NgxSpinnerService
    ) {
        this.stockistOrder = new StockistPurchaseOrderDetail({});
    }

    ngOnInit() {
        this.stockistOrder.promotions.map((pr) => {
            pr.promotionType === "Gift" ? this.orderIsHavePromotionGift = true : ""
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["stockistOrder"] && changes["stockistOrder"].currentValue) {
            this.setAdditionalDetails();
        }
    }

    onClickOpenCancelModal() {
        this.openCancelModal = true
    }

    onClickCloseModal() {
        this.openCancelModal = false
    }

    setAdditionalDetails() {
        this.additionalDetails = [
            { label: this.translate.instant('ORDER_INFO.CONFIRMED.USER_NAME'), value: this.stockistOrder.retailerName },
            { label: this.translate.instant('ORDER_INFO.CONFIRMED.TAX_ID'), value: this.stockistOrder.taxid },
            { label: this.translate.instant('ORDER_INFO.CONFIRMED.PHONE_NUMBER'), value: this.stockistOrder.mobilePhoneNumber },
            { label: this.translate.instant('ORDER_INFO.CONFIRMED.SECONDARY_NUMBER'), value: this.stockistOrder.secondaryPhoneNumber || "-" }
        ];
    }

    getTotalQuantity(): number {
        if (this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getTotalAmount(): number {
        if (this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getNetAmount(): number {
        const netAmount =this.stockistOrder.netAmount
        return netAmount;
    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.stockistOrder.couponDiscount,
            promotionDiscount: this.stockistOrder.promotionDiscount,
            shippingCost: this.stockistOrder.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    // isDeliveryDelayed(): boolean {
    //     const etd = this.stockistOrder.estimateTimeDelivery;
    //     if (!etd) return false;

    //     const date = new Date();
    //     date.setDate(date.getDate() - 1); // Yesterday

    //     return date.getTime() > new Date(etd).getTime();
    // }

    updateStatusToDelivery() {
        this.isLoading = true;
        this.spinner.show('spinner');
        this.stockistService.updateStatusToDelivery(this.stockistOrder.id).subscribe({
            next: () => {
                this.router.navigate([`stockist/order/${this.stockistOrder.id}`]).then(() => { window.location.reload() })
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
            error: (err) => {
                console.error('Error updating order status to delivery', err);
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
        });
    }

    navigateToOldOrder() {
        window.localStorage.setItem('page', 'oldOrder');
        this.router.navigateByUrl(`stockist/order/${this.stockistOrder.refOrderId}`).then(() => window.location.reload())
    }

    goBack(): void {
        const page = window.localStorage.getItem('page');
        if (page) {
            if (page === 'landing') {
                this.router.navigate(['/stockist/orders']);
            } else if (page === 'history') {
                this.router.navigate(['/stockist/order-history']);
            } else if (page === 'notification') {
                this.router.navigate(['/stockist/my-notification']);
            } else if (page === 'oldOrder') {
                window.history.back();
                setTimeout(() => {
                    window.location.reload()
                }, 100)
            }
        } else {
            window.history.back();
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }

    }
}
