<ngx-spinner
    bdColor="rgb(0,0,0,0.07)"
    size="medium"
    color="#21C063"
    type="ball-clip-rotate"
    [fullScreen]="false"
    name="spinner"
    style="margin-top: 0px !important"
></ngx-spinner>
<div class="bg-grey">
    <div class="aura-container-custom">
        <div class="px-0">
            <div
                class="back-button justify-content-start pointer-cursor"
                (click)="goBack()"
            >
                <i
                    class="fa-solid fa-arrow-left"
                    style="width: 2.25rem"
                    aria-hidden="true"
                ></i>
                <span>{{ 'ACTION.BACK_PAGE' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="d-flex box mt-2">
        <div class="flex w-100" style="padding: 8px">
            <div class="d-flex justify-content-between" (click)="switchLanguage('th')">
                <span class="title">ภาษาไทย</span>
                <img *ngIf="currentLang === 'th'" src="../../../../../../../assets/images/check.png"> 
            </div>
            <div class="d-flex justify-content-between" style="padding-top: 24px;" (click)="switchLanguage('en')">
                <span class="title">English</span>
                <img *ngIf="currentLang === 'en'" src="../../../../../../../assets/images/check.png">
            </div>
            <!-- <div class="d-flex text">TH</div> -->
        </div>
    </div>
</div>
