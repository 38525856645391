import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { TabProductShelfStatus } from '@core/models/stockist/stockist-enum';
import { ProductParams } from '@core/models/stockist/product.model';
import { StockistProduct } from '@core/models/stockist/stockist-product-shelf';
import { ProductShelfService } from '@core/services/stockist/product-shelf.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs';

export interface FilterEvent {
  params: ProductParams;
  totalSelected: number;
}
@Component({
  selector: 'app-product-shelf-list',
  templateUrl: './product-shelf-list.component.html',
  styleUrls: ['./product-shelf-list.component.scss']
})
export class ProductShelfListComponent implements OnInit {
  search: FormControl = new FormControl();
  stockistProductList: StockistProduct[] = [];
  total = 0;
  totalPage = 0;
  filterTotal = 0;
  tabSelected = TabProductShelfStatus.All;
  statuses = [
    { value: TabProductShelfStatus.All, name: this.translate.instant('SHELF_MANAGEMENT.TAB_STATUS.ALL') },
    { value: TabProductShelfStatus.Active, name: this.translate.instant('SHELF_MANAGEMENT.TAB_STATUS.ACTIVE') },
    { value: TabProductShelfStatus.InActive, name: this.translate.instant('SHELF_MANAGEMENT.TAB_STATUS.INACTIVE') }
  ];
  isOpenFilterModal = false
  isLoading = true;
  hasMoreData = true;
  params: ProductParams = {
    status: TabProductShelfStatus.All,
    limit: 50,
    page: 1,
    datatable: true,
    orderBy: 'CreatedAt_desc'
  };
  currentLang = 'th';
  productIdSelected = 0;
  constructor(
    private translate: TranslateService,
    private productShelfService: ProductShelfService,
    private spinner: NgxSpinnerService,
  ) {
    this.spinner.show('spinner');
  }
  openCancelModal = false;

  ngOnInit(): void {
    this.search.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.params.keyword = value;
      this.params.page = 1;
      this.stockistProductList = [];
      this.loadData();
    });
    this.loadData();
    this.currentLang = this.translate.currentLang;
  }

  loadData() {
    this.spinner.show('spinner');
    this.isLoading = true;
    this.productShelfService.getStockistProductList(this.params).subscribe(res => {
      this.spinner.hide('spinner');
      this.isLoading = false;
      const newProduct = res.data;
      this.stockistProductList.push(...newProduct);
      this.total = res.total;
      this.totalPage = res.totalPage;
    })
  }

  goBack(): void {
    window.history.back();
  }

  onChangeTab(value: TabProductShelfStatus) {
    this.tabSelected = value;
    this.params.status = value;
    this.params.page = 1;
    this.stockistProductList = [];
    this.loadData();
  }

  getStatusName(status: string) {
    return status === TabProductShelfStatus.Active ? this.translate.instant('SHELF_MANAGEMENT.TAB_STATUS.ACTIVE') : this.translate.instant('SHELF_MANAGEMENT.TAB_STATUS.INACTIVE');
  }

  openFilterModal() {
    this.isOpenFilterModal = true;
  }

  filterProduct(filterEvent: FilterEvent) {
    this.filterTotal = filterEvent.totalSelected;
    const keyword = this.params.keyword;
    const status = this.params.status;
    this.params = {
      keyword,
      status,
      ...filterEvent.params,
    };
    this.stockistProductList = [];
    // this.hasMoreData = true;
    this.loadData();
  }

  onCloseFilterProduct(filterEvent: FilterEvent) {
    this.filterTotal = filterEvent.totalSelected;
    const keyword = this.params.keyword;
    const status = this.params.status;
    this.params = {
      keyword,
      status,
      ...filterEvent.params,
    };

  }

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/image_error.jpg';
  }

  onScroll() {
    if (
      this.params?.page && this.params?.page < this.totalPage
    ) {
      this.params.page++;
      this.loadData();
    }
  }
  onClickOpenCancelModal(productIdSelected: number) {
    this.productIdSelected = productIdSelected
    this.openCancelModal = true
  }

  onClickCloseModal() {
    this.openCancelModal = false
    this.params.page = 1;
    this.stockistProductList = [];
    this.loadData();
  }

}
