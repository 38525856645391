/* eslint-disable @typescript-eslint/no-explicit-any */
export class PreviewOrder {
  retailerAddress: string;
  summaryAmount: number;
  orders: OrderDetail[];

  constructor(data: any) {
    this.retailerAddress = data.retailerAddress;
    this.summaryAmount = data.summaryAmount;
    this.orders = data.orders?.map((x: OrderDetail) => new OrderDetail(x));
  }
}

export class OrderDetail {
  amount: number;
  discount: number;
  shippingCost: number;
  netAmount: number;
  stockistName: string;
  items: PurchaseOrderItem[];

  constructor(data: OrderDetail) {
    this.amount = data.amount;
    this.discount = data.discount;
    this.shippingCost = data.shippingCost;
    this.netAmount = data.netAmount;
    this.stockistName = data.stockistName;
    this.items = data.items?.map(
      (x: PurchaseOrderItem) => new PurchaseOrderItem(x)
    );
  }
}

export class PurchaseOrderItem {
  id: number;
  productId: number;
  coverImagePathThump: string;
  productName: string;
  productNameEN: string;
  quantity: number;
  amount: number;
  unitAmount: number;
  promotionDiscount: number;
  totalAfterPromotionDiscount: number;

  constructor(data?: any) {
    this.id = data?.id || 0;
    this.productId = data?.productId || 0;
    this.coverImagePathThump = data?.coverImagePathThump || '';
    this.productName = data?.productName || '';
    this.productNameEN = data?.productNameEN || '';
    this.quantity = data?.quantity || 0;
    this.amount = data?.amount || 0;
    this.unitAmount = data?.unitAmount || 0;
    this.promotionDiscount = data?.promotionDiscount || 0;
    this.totalAfterPromotionDiscount = data?.totalAfterPromotionDiscount || 0;
  }
}

export class PurchaseOrderPromotion {
  promotionId: number;
  promotionType: string;
  promotionName: string;
  quantity: number;
  giveAway: PromotionGiveAway;

  constructor(data?: any) {
    this.promotionId = data?.promotionId || 0;
    this.promotionType = data?.promotionType || '';
    this.promotionName = data?.promotionName || '';
    this.quantity = data?.quantity || 0;
    this.giveAway = data?.amount || {};
  }
}

export class PromotionGiveAway {
  id: number;
  name: string;
  coverImagePathThump: string;

  constructor(data?: any) {
    this.id = data?.id || 0;
    this.name = data?.name || '';
    this.coverImagePathThump = data?.coverImagePathThump || '';
  }
}

export class PurchaseOrderActions {
  id: number;
  status: string;
  actionRequest: string;
  actionDate: string;
  actionBy: string;
  actionByID: number;
  actionReasons: string;
  constructor(data: PurchaseOrderActions) {
    this.id = data.id;
    this.status = data.status;
    this.actionRequest = data.actionRequest;
    this.actionDate = data.actionDate;
    this.actionBy = data.actionBy;
    this.actionByID = data.actionByID;
    this.actionReasons = data.actionReasons;
  }
}

