import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { IOrderDetail } from '@core/models/stockist/order.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-order-processed-cancelled',
    templateUrl: './order-processed-cancelled.component.html',
    styleUrls: ['./order-processed-cancelled.component.scss'],
})
export class OrderProcessedCancelledComponent implements OnInit {
    data!: IOrderDetail;
    orderId = '';

    orderIsHavePromotionGift = false;

    constructor(
        private route: Router,
        private stockistService: StockistService,
        private activateRoute: ActivatedRoute,
        private translate: TranslateService
    ) { this.translate.setDefaultLang('th'); }
    ngOnInit(): void {
        this.orderId = this.activateRoute.snapshot.paramMap.get('order-number')?? '';
        this.getOrderDetail();
    }

    navigateToLanding() {
        this.route.navigate(['/stockist']);
    }

    navigateToOrderInfoCancelled() {
        this.route.navigate([`stockist/order/${this.orderId}`]);
    }

    sumTotalQuantity(): number {
        let totalQuantity = 0;
        if (this.data) {
            for (const data of this.data.items) {
                totalQuantity += data.quantity;
            }
        }
        return totalQuantity;
    }
    getOrderDetail() {
        this.stockistService.getOrderDetail(this.orderId).subscribe({
            next: (res) => {
                this.data = res;
                res.promotions.map((pr) => {
                    pr.promotionType === "Gift"? this.orderIsHavePromotionGift = true : ""
                })
            },
        });
    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.data.couponDiscount,
            promotionDiscount: this.data.promotionDiscount,
            shippingCost: this.data?.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    getNetAmount(): number {
        if (!this.data) return 0
        const netAmount = this.data.netAmount;
        return netAmount

    }

    getTotalAmount(): number {
        if (this.data && this.data.items) {
            const totalQuantity = this.data.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getTotalQuantity(): number {
        if (this.data && this.data.items) {
            const totalQuantity = this.data.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }
    
    handleImageError(event: Event) {
        const target = event.target as HTMLImageElement;
        target.src = 'assets/images/image_error.jpg';
    }
}
