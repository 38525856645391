/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpEvent,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { switchMap, catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
    private refreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<any> | null = null;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');
        const exceptUrls = [
            'stockist/verify-phone',
            'stockist/login',
            'stockist/request-otp',
            'stockist/verify-otp',
            'stockist/set-password',
            'stockist/onboard',
            'api/customer/retailer',
            'api/line/init',
        ];

        const isExceptUrl = exceptUrls.some((url) => req.url.includes(url));

        if (!token && !isExceptUrl) {
            this.router.navigate(['/auth']);
            return throwError(() => new Error('Token not found, please login again'));
        }

        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return this.authService.isTokenExpired().pipe(
            switchMap(isExpired => {
                if (isExpired && !isExceptUrl) {
                    return this.handle(req, next);
                } else {
                    return this.sendRequest(req, next);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !isExceptUrl) {
                    this.router.navigate(['/auth']);
                }
                return throwError(() => error);
            })
        );
    }

    handle(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (this.refreshingToken) {
            return this.refreshTokenSubject!.pipe(
                switchMap(() => this.sendRequest(req, next))
            );
        } else {
            this.refreshingToken = true;
            this.refreshTokenSubject = new BehaviorSubject<any>(null);

            return this.authService.refreshToken().pipe(
                tap(res => {
                    localStorage.setItem('token', res.accessToken);
                    localStorage.setItem('refreshtoken', res.refreshToken);
                    this.refreshTokenSubject?.next(res);
                }),
                finalize(() => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject!.complete();
                    this.refreshTokenSubject = null;
                }),
                switchMap(() => {
                    window.location.reload();
                    return this.sendRequest(req, next);
                }),
                catchError(err => {
                    this.authService.logout();
                    this.router.navigate(['/auth']);
                    this.refreshingToken = false;
                    this.refreshTokenSubject!.complete();
                    this.refreshTokenSubject = null;
                    return throwError(() => err);
                })
            );
        }
    }


    private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const authReq = this.setHeader(req);
        return next.handle(authReq);
    }

    private setHeader(req: HttpRequest<any>): HttpRequest<any> {
        const token = localStorage.getItem('token');
        const exceptUrls = ['api/line/init', 'auth/line', 'api/line/request-login'];
        const refreshToken = localStorage.getItem('refreshtoken')

        if (req.url.includes('/stockist/refresh')) {
            return req.clone({
                headers: req.headers
                    .set('Authorization', `Bearer ${refreshToken ? refreshToken : ''}`)
            });
        }

        else if (exceptUrls.some((url) => req.url.includes(url))) {
            return req.clone({
                headers: req.headers
                    .set('Authorization', `Bearer ${token ? token : ''}`)
            });
        }

        return req.clone({
            headers: req.headers
                .set('Content-Type', 'application/json')
                .set('locale', 'th')
                .set('Authorization', `Bearer ${token ? token : ''}`),
        });
    }
}
