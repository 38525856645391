import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptor } from '@core/interceptors/interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { LayoutStockistComponent } from './layouts/layout-stockist/layout-stockist.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { StockistModule } from '@modules/main/pages/stockist/stockist.module';
import { SharedModule } from '@modules/shared/shared.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import { TranslationService } from '@modules/shared/i18n/translation.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

registerLocaleData(localeTh, 'th');
const initialConfig = () => {
    const el = document.getElementById('web-config');
    if (el) {
        const config = el.innerText.trim();
        if (config) {
            environment.CONFIG = JSON.parse(config);
            for (const c in environment.CONFIG) {
                environment[c] = environment.CONFIG[c];
            }
        }
        el.remove();
    }
};

@NgModule({
    declarations: [AppComponent, LayoutStockistComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        StockistModule,
        SharedModule,
        InfiniteScrollModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        NgxSpinnerModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: true,
        //   // Register the ServiceWorker as soon as the application is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: initialConfig,
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        TranslationService,
        TranslateService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(translate: TranslateService) {
        translate.addLangs(['en', 'th']);

        if (localStorage.getItem('local')) {
            translate.use(localStorage.getItem('local')!);
        } else {
            translate.setDefaultLang('en');
            const browserLang = translate.getBrowserLang() || 'en';
            translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
        }
    }
}
