import { environment } from '@environments/environment.develop';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PromotionValidate } from '@core/models/stockist/promotion.validate';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(private http: HttpClient) { }
  validatePromotion(order: PromotionValidate): Observable<PromotionValidate> {

    return this.http.post<PromotionValidate>(
      `${environment.promotionApi}/promotions/get_eligible_promotions`,
      order
    );

  }
}
