<div>
    <nav class="navigate-bar">
        <div class="back-container" (click)="goBack()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_HISTORY.GO_BACK" | translate }}</label>
        </div>
    </nav>
    <div>
        <p class="title">{{ 'SHELF_MANAGEMENT.LIST_TITLE' | translate}}</p>
        <div class="tab-section">
            <div class="tab-item" *ngFor="let status of statuses" [ngClass]="{ actived: status.value === tabSelected }"
                (click)="onChangeTab(status.value)">
                <label for="status-name">{{ status.name }}</label>
            </div>
        </div>
        <div class="search-group">
            <input type="text" class="input-search" placeholder="{{ 'ACTION.SEARCH' | translate}}"
                [formControl]="search" />
            <div class="icon-group">
                <i ngClass="fa-solid fa-magnifying-glass"></i>
            </div>
        </div>
        <div *ngIf="isLoading" class="overlay">
            <ngx-spinner bdColor="rgb(0,0,0,0)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
        </div>
        <div class="d-flex px-3 px-md-4 justify-content-between align-items-center align-self-stretch text-search-result"
            [class.filterSelected]="stockistProductList.length === 0">
            <span *ngIf="stockistProductList?.length">{{total}} {{ 'SHELF_MANAGEMENT.PRODUCT_UNIT' | translate}}</span>
            <button class="fillter-btn" *ngIf="filterTotal || stockistProductList.length" [class.selected]="filterTotal" (click)="openFilterModal()">
                <img [src]="filterTotal ? 'assets/icons/fillter-white_icon.svg' : 'assets/icons/fillter_icon.svg'">
                {{ 'SHELF_MANAGEMENT.FILTER_TITLE' | translate}}
                <div class="d-flex align-content-center">
                    <span class="number-circle" *ngIf="filterTotal">
                        {{ filterTotal }}
                    </span>
                </div>
            </button>
        </div>
        <div *ngIf="!stockistProductList.length && !isLoading" class="empty-shelf">
            <div class="content">
                <img src="assets/images/Empty_Shelf_Image.png" alt="Empty history image">
                <h5>{{ "SHELF_MANAGEMENT.EMPTY_SHELF_TITLE" | translate}}</h5>
                <p>{{ "SHELF_MANAGEMENT.EMPTY_SHELF_DESCRIPTION" | translate}}</p>
            </div>
        </div>
        <div class="mx-3 mt-2" infiniteScroll [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" >
            <div *ngFor="let product of stockistProductList" class="product-container" (click)="onClickOpenCancelModal(product.product.id)">
                <div class="product-item">
                    <div class="img-product">
                        <img [src]="product.product.coverImagePathThump || 'assets/images/image_error.jpg'" (error)="onImageError($event)">
                    </div>
                    <div class="product-text">
                        <span>{{ currentLang === 'th' ? product.product.name : product.product.nameEN }}</span>
                        <div class="d-flex justify-content-between mt-1">
                            <span class="fw-semibold">{{(product.price) | currency : "THB" : "symbol-narrow"}}</span>
                            <span [class]="product.status === 'Active' ? 'active-status' : 'inactive-status'">{{ getStatusName(product.status) }}</span>
                        </div>
                    </div>
                </div>
                <div class="btn icon-group">
                    <i ngClass="fa-solid fa-chevron-right"></i>
                </div> 
            </div>
        </div>
        <app-modal-edit-shelf *ngIf="openCancelModal" [isOpenModal]="openCancelModal"
        (isOpenModalChange)="onClickCloseModal()" [productId]="productIdSelected"/>
    </div>

    <div *ngIf="isOpenFilterModal">
      <app-filter-product-modal [(isOpenModal)]="isOpenFilterModal" [selectedBrand]="params.brandIds || []"
          [selectedCategory]="params.categoryIds || []" [selectedStockist]="params.stockistIds || []"
          [orderBy]="params.orderBy || null" [limit]="params.limit" (filterEvent)="filterProduct($event)"
          (closeFIlterEvent)="onCloseFilterProduct($event)"></app-filter-product-modal>
    </div>
</div>
