import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import { Injectable } from '@angular/core';

const THAI_MONTH_NAMES = [
  "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
];
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = date.getDate().toString().padStart(2, '0');
      const monthIndex = date.getMonth();
      const month = THAI_MONTH_NAMES[monthIndex];
      const year = date.getFullYear() + 543;
      return `${day} ${month} ${year}`;
    } else if (displayFormat === 'monthYear') {
      const monthIndex = date.getMonth();
      const month = THAI_MONTH_NAMES[monthIndex];
      const year = (date.getFullYear() + 543).toString().substring(2);
      return `${month} ${year}`;
    }
    return date.toLocaleDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'monthYear',
    monthYearLabel: 'monthYear',
    dateA11yLabel: 'input',
    monthYearA11yLabel: 'input',
  },
};

export const APP_DATE_FORMATS_TH = (inputDate: string) => {
  const monthsThai = [
    'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
    'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
  ];

  const daysThai = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'];

  const date = new Date(inputDate);
  const dayThai = daysThai[date.getDay()];
  const monthThai = monthsThai[date.getMonth()];
  const yearThai = date.getFullYear() + 543;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} น.`;
  
  return `${dayThai} ${date.getDate()} ${monthThai} ${yearThai}, ${formattedTime}`;
}
