<div class="aura-container-custom">
    <div class="stockistBanner d-flex gap-3">
        <div class="bannerLogo">
            <img [src]="imagePath" onerror="this.src='assets/images/image_error.jpg'" />
        </div>
        <div class="bannerText">
            <div class="bannerName">{{ stockistProfile.name }}</div>
            <div class="bannerRating">
                <div>
                    <button type="button" class="btn history-order" routerLink="/stockist/order-history">
                        <div class="mx-auto">
                            <img class="mx-auto" src="assets/icons/order-btn.svg" alt="" />
                        </div>
                        <div class="text-box">
                            {{ "ORDERS.ORDER_ALL" | translate }}
                        </div>
                    </button>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div>
            <div class="card">
                <div class="d-flex pt-3">
                    <div class="card-body1">
                        <div>
                            <div style="
                                    position: relative;
                                    display: inline-block;
                                ">
                                <svg width="50" height="50">
                                    <circle cx="25" cy="25" r="20" fill="white" />
                                </svg>
                                <img src="../../../../../../../assets/icons/wallet_fill.svg" alt="wallet-icon" style="
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 30px;
                                        height: 30px;
                                    " />
                            </div>
                            <p style="font-size: 0.825rem; margin: 0">
                                {{ "ORDERS.TOTAL_MONTHLY_AMOUNT" | translate }}
                            </p>
                        </div>
                        <div class="d-flex gap-1 percent" [class.skeleton]="isLoading">
                            <h2 [ngStyle]="{'display': isLoading ?'none' : ''}">{{(dataSummary.totalOrderAmount)|
                                currency : "THB" : "symbol-narrow"}}</h2>
                            <div *ngIf="dataSummary.totalOrderAmountChangePercent!==0" class="cardPadding">
                                <p [ngStyle]="{'color': dataSummary.totalOrderAmountChangePercent<0 ? 'red' : 'green'}">
                                    {{ dataSummary.totalOrderAmountChangePercent<0 ? '' : '+' }}
                                        {{(dataSummary.totalOrderAmountChangePercent) | number: '1.2-2' }}% <i
                                        class="fas"
                                        [ngClass]="{'fa-arrow-down': dataSummary.totalOrderAmountChangePercent<0, 'fa-arrow-up': dataSummary.totalOrderAmountChangePercent>=0}">
                                        </i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body2 px-3">
                        <div>
                            <div>
                                <span>{{ "ORDERS.TOTAL_ORDER" | translate }}</span>
                                <h1 class="d-flex gap-1 percent" [class.skeleton]="isLoading">
                                    <h2 [ngStyle]="{'display': isLoading ?'none' : ''}">{{(dataSummary.totalOrder)}}
                                    </h2>
                                    <div *ngIf="dataSummary.totalOrderChangePercent!==0" class="cardPadding">
                                        <p
                                            [ngStyle]="{'color': dataSummary.totalOrderChangePercent<0  ? 'red' : 'green'}">
                                            {{ dataSummary.totalOrderChangePercent<0 ? '' : '+' }}
                                                {{(dataSummary.totalOrderChangePercent) | number: '1.2-2' }}% <i
                                                class="fas"
                                                [ngClass]="{'fa-arrow-down': dataSummary.totalOrderChangePercent<0, 'fa-arrow-up': dataSummary.totalOrderChangePercent>=0}">
                                                </i>
                                        </p>
                                    </div>
                                </h1>
                            </div>
                            <div>
                                <span>{{ "ORDERS.TOTAL_NEW_CUSTOMER" | translate }}</span>
                                <h1 class="d-flex gap-1 percent" [class.skeleton]="isLoading">
                                    <h2 [ngStyle]="{'display': isLoading ?'none' : ''}">{{ dataSummary.newCustomer }}
                                    </h2>
                                    <div *ngIf="dataSummary.newCustomerChangePercent!==0" class="cardPadding">
                                        <p
                                            [ngStyle]="{'color': dataSummary.newCustomerChangePercent<0 ? 'red' : 'green'}">
                                            {{ dataSummary.newCustomerChangePercent<0 ? '' : '+' }}
                                                {{(dataSummary.newCustomerChangePercent) | number: '1.2-2' }}% <i
                                                class="fas"
                                                [ngClass]="{'fa-arrow-down': dataSummary.newCustomerChangePercent<0 , 'fa-arrow-up': dataSummary.newCustomerChangePercent>=0}">
                                                </i>
                                        </p>
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cardButton d-flex justify-content-center">
                    <button class="border-0" routerLink="/stockist/dashboard" type="button" data-bs-toggle="offcanvas">
                        <img class="mx-auto" src="assets/icons/chart.svg" alt="" />
                        &nbsp; {{ "ORDERS.VIEW_DASHBOARD" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="button-group">
            <button type="button" class="btn" routerLink="/stockist/product-shelf">
                <img class="mx-auto" src="assets/icons/all-product-btn.svg" alt="" />
                <div class="text-box">
                    <p>{{ "ORDERS.PRODUCT_TITLE" | translate }}</p>
                    <p class="product-quantity">({{ stockistProductCount }})</p>
                    <p></p>
                </div>
            </button>
            <!-- <button type="button" class="btn" routerLink="/stockist/order-history">
                <div class="mx-auto">
                    <img class="mx-auto" src="assets/icons/order-btn.svg" alt="" />
                </div>
                <div class="text-box">
                    <p>คำสั่งซื้อทั้งหมด</p>
                    <p></p>
                </div>
            </button> -->
            <!-- <button type="button" class="btn">
                <div class="mx-auto">
                    <img class="mx-auto" src="assets/icons/promotion-btn.svg" alt="" />
                </div>
                <div class="text-box">
                    <p>โปรโมชั่น</p>
                    <p></p>
                </div>
            </button> -->
        </div>
    </div>
    <!-- </div> -->
    <!-- <div class="d-flex justify-content-between px-3">
  <p class="fs-5">รอดำเนินการ</p>
  <div class="d-flex align-items-baseline gap-1" type="button">
    <p
      class="fs-6"
      style="color: #21c063;"
      routerLink="/stockist/view-all-orders"
    >
      ดูทั้งหมด
    </p>
    <i class="fa-solid fa-arrow-right" style="color: #21c063;"></i>
  </div>
  </div> -->
    <ngx-spinner [bdColor]="'rgba(0,0,0,0.1)'" [size]="'medium'" [color]="'#21c063'" [type]="'ball-clip-rotate'"
        [name]="'spinner'">
    </ngx-spinner>
    <ng-container *ngIf="
                pending.length > 0 ||
                confirmed.length > 0 ||
                cancelrequested.length > 0;
            else emptyState
        ">
        <div *ngFor="
                let status of [
                    {
                        name: ('ORDERS.STATUS_PENDING' | translate),
                        orders: pending,
                        badgeColor: '#C4CFFE',
                        fontColor: '#4A57A5',
                        page: 1,
                        text: 'Pending',
                        total: stockistOrderStatusTotal.totalPendingOrder
                    },
                    {
                        name: ('ORDERS.STATUS_CONFIRMED' | translate),
                        orders: confirmed,
                        badgeColor: '#A6EBEB',
                        fontColor: '#238484',
                        page: 2,
                        text: 'Confirmed',
                        total: stockistOrderStatusTotal.totalConfirmOrder
                    },
                    {
                        name: ('ORDERS.STATUS_CANCEL_REQUESTED' | translate),
                        orders: cancelrequested,
                        badgeColor: '#FFDDBD',
                        fontColor: '#ED7201',
                        page: 3,
                        text: 'CancelRequested',
                        total: stockistOrderStatusTotal.totalCancelRequestedOrder
                    }
                ]
            ">
            <div *ngIf="status.orders.length > 0">
                <div class="head mt-3 mx-3 d-flex justify-content-between">
                    <span class="d-flex">
                        {{ status.name }} : &nbsp;
                        <div class="totalBadge px-2" [style.background-color]="status.badgeColor"
                            [style.color]="status.fontColor">
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2.33301 0C1.22844 0 0.333008 0.895431 0.333008 2V3.16667C0.333008 3.6269 0.706104 4 1.16634 4H2.33301V10.6667C2.33301 11.7712 3.22844 12.6667 4.33301 12.6667H10.9997C12.1042 12.6667 12.9997 11.7712 12.9997 10.6667V9.5C12.9997 9.03976 12.6266 8.66667 12.1663 8.66667H11.6663V2C11.6663 0.89543 10.7709 0 9.66634 0H2.33301ZM2.33301 1.33333C1.96482 1.33333 1.66634 1.63181 1.66634 2V2.66667H2.33301V1.33333ZM5.55257 11.3333H10.9997C11.3679 11.3333 11.6664 11.0349 11.6664 10.6667V10H5.66638V10.6667C5.66638 10.9004 5.62627 11.1248 5.55257 11.3333ZM5.66634 3.33333C5.29815 3.33333 4.99967 3.63181 4.99967 4C4.99967 4.36819 5.29815 4.66667 5.66634 4.66667H8.33301C8.7012 4.66667 8.99967 4.36819 8.99967 4C8.99967 3.63181 8.7012 3.33333 8.33301 3.33333H5.66634ZM4.99967 6.66667C4.99967 6.29848 5.29815 6 5.66634 6H6.99967C7.36786 6 7.66634 6.29848 7.66634 6.66667C7.66634 7.03486 7.36786 7.33333 6.99967 7.33333H5.66634C5.29815 7.33333 4.99967 7.03486 4.99967 6.66667Z"
                                    [attr.fill]="status.fontColor" />
                            </svg>
                            <span class="ms-1 mb-1">{{status.total}}</span>
                        </div>
                    </span>
                    <button [routerLink]="[
                            '/stockist/order-history',
                            { selectedTab: status.text }
                        ]">
                        {{ "ORDERS.VIEW_ALL" | translate }}<i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>

                <ng-container *ngFor="let order of status.orders | slice : 0 : 3">
                    <div (click)="navigateToOrder(order)" class="d-flex flex-column mt-3 mx-3 orderList"
                        aria-hidden="true">
                        <div class="order-card">
                            <div class="d-felx">
                                <div class="card-topic">
                                    <div class="retailer-name-icon">
                                        <img class="mx-auto" src="assets/icons/shop.svg" alt="" />
                                        <p class="order-retail-name">
                                            {{ order.retailerName }}
                                        </p>
                                    </div>
                                    <p class="font-black total-price">
                                        {{ order.netAmount | currency : 'THB' : 'symbol-narrow'}}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between order-id-date">
                                <p class="order-id-font">
                                    {{ "ORDERS.ORDER_ID" | translate }} {{ order.id }}
                                </p>
                                <!-- <p class="order-time">{{ order.createdAt | displayDate }}</p> -->
                            </div>
                            <div class="d-flex justify-content-between order-id-date">
                                <div class="test">
                                    <p class="order-date-font">
                                        {{ "ORDERS.ORDER_AT" | translate }} {{ order.createdAt | displayDate }}
                                    </p>
                                </div>
                                <div class="test">
                                    <p class="order-date-font pending" *ngIf="order.status === 'Pending'">
                                        {{ "ORDERS.STATUS_PENDING" | translate }}
                                    </p>
                                    <p class="order-date-font confirmed" *ngIf="order.status === 'Confirmed'">
                                        {{ "ORDERS.STATUS_CONFIRMED" | translate }}
                                    </p>
                                    <p class="order-date-font cancel-requested"
                                        *ngIf="order.status === 'CancelRequested'">
                                        {{ "ORDERS.STATUS_CANCEL_REQUESTED" | translate }}
                                    </p>
                                    <p class="order-date-font cancelled" *ngIf="order.status === 'Cancelled'">
                                        {{ "ORDERS.STATUS_CANCELLED" | translate }}
                                    </p>
                                    <p class="order-date-font delivered" *ngIf="order.status === 'Delivered'">
                                        {{ "ORDERS.STATUS_DELIVERED" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex order-quantity-fee-day">
                            <div class="test">
                                <div class="d-flex">
                                    <div>
                                        <img src="../../../../../../../assets/icons/product.svg" />
                                    </div>
                                    <p>{{ "ORDERS.PRODUCT_QUANTITY" | translate }}</p>
                                </div>
                                <span>{{ order.totalQuantity }}</span>
                                <span>{{ "ORDERS.ITEM" | translate }}</span>
                            </div>
                            <div class="test">
                                <div class="d-flex">
                                    <i class="fa-solid fa-truck" style="color: #f43a29"></i>
                                    <p>{{ "ORDERS.DELIVERY_FEE" | translate }}</p>
                                </div>
                                <span>฿{{ order.deliveryFee }}</span>
                            </div>
                            <div class="test">
                                <div class="d-flex">
                                    <i class="fa-solid fa-calendar" style="color: #ffc130"></i>
                                    <p>{{ "ORDERS.DELIVERED_DATE" | translate }}</p>
                                </div>
                                <div class="open-day">
                                    <div *ngFor="let day of days">
                                        <span style="margin-right: 5px;" [ngStyle]="{
                                                color: isDayActive(
                                                    day,
                                                    order.officeDays
                                                )
                                                    ? colors[1]
                                                    : 'black'
                                            }">{{ day }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #emptyState>
        <div class="empty-order" *ngIf="!loading_data">
            <div class="content">
                <img src="../../../../../../../assets/images/empty-history.png" alt="Empty history image" />
                <p>{{ "ORDERS.EMPTY_ORDER" | translate }}</p>
            </div>
        </div>
    </ng-template>
</div>