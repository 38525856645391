import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICart,
  CartAmount,
  IConfirmCartItem,
  IUpdateCartItem,
} from '@core/models/retailer/cart.model';
import { PreviewOrders } from '@core/models/retailer/order-preview.model';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private http: HttpClient) {}
    private cartChangedSource = new Subject<void>();
    cartChanged = this.cartChangedSource.asObservable();

    private cartItemCount = new BehaviorSubject(0);
    public currentItemCount = this.cartItemCount.asObservable();

    notifyCartChanged() {
        this.cartChangedSource.next();
    }

    getCart(): Observable<APIResponse<ICart>> {
        return this.http.get<APIResponse<ICart>>(`${environment.apiUrl}/cart`).pipe(
            tap((response) => {
                if (response && response.data) {
                    const count = response.data.items.reduce((acc, item) => acc + item.quantity, 0);
                    this.cartItemCount.next(count);
                }
            }),
            map(response => response)
        );
    }

  updateCartItems(payload: IUpdateCartItem[]): Observable<APIResponse<ICart>> {
    return this.http.put<APIResponse<ICart>>(
      `${environment.apiUrl}/cart/item`,
      payload
    ).pipe(
        tap(() => this.notifyCartChanged())
    )
  }

  getCartAmount(): Observable<APIResponse<CartAmount>> {
    return this.http.get<APIResponse<CartAmount>>(
      `${environment.apiUrl}/cart/amount`
    ).pipe(
        tap(() => this.notifyCartChanged())
    )
  }

  confirmCart(payload: IConfirmCartItem[]): Observable<APIResponse<null>> {
    return this.http.put<APIResponse<null>>(
      `${environment.apiUrl}/cart/confirm`,
      payload
    ).pipe(
        tap(() => this.notifyCartChanged())
    )
  }

  getOrderPreview(): Observable<APIResponse<PreviewOrders>> {
    return this.http.get<APIResponse<PreviewOrders>>(
      `${environment.apiUrl}/cart/preview-orders`
    ).pipe(
        tap(() => this.notifyCartChanged())
    )
  }
}
