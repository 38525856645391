<div class="aura-container-custom">
    <div>
        <nav class="navbar bg-body-tertiary">
            <div class="container-fluid d-flex align-items-center">
                <i class="fa-solid fa-angle-left" style="width: 2.25rem" aria-hidden="true"
                    routerLink="/stockist/orders"></i>
                <div data-cy="label-page-view-all-orders" class="fs-5 navbar-brand">
                    คำสั่งซื้อทั้งหมด
                </div>
                <span></span>
            </div>
        </nav>

        <div class="new-tab" data-cy="tab-bar-menu">
            <a (click)="selectTab(1)" [ngClass]="{ 'active-tab': selectedTab === 1 }" (keydown.enter)="selectTab(1)"
                tabindex="0" data-cy="tab-pending">รอยืนยัน</a>
            <a (click)="selectTab(2)" [ngClass]="{ 'active-tab': selectedTab === 2 }" (keydown.enter)="selectTab(2)"
                tabindex="0" data-cy="tab-request-cancel">ขอยกเลิก</a>
            <a (click)="selectTab(3)" [ngClass]="{ 'active-tab': selectedTab === 3 }" (keydown.enter)="selectTab(3)"
                tabindex="0" data-cy="tab-confirmed">ยืนยันแล้ว</a>
            <a (click)="selectTab(4)" [ngClass]="{ 'active-tab': selectedTab === 4 }" (keydown.enter)="selectTab(4)"
                tabindex="0" data-cy="tab-cancelled">ยกเลิก</a>
            <a (click)="selectTab(5)" [ngClass]="{ 'active-tab': selectedTab === 5 }" (keydown.enter)="selectTab(5)"
                tabindex="0" data-cy="tab-delivered">สำเร็จ</a>
            <a (click)="selectTab(6)" [ngClass]="{ 'active-tab': selectedTab === 6 }" (keydown.enter)="selectTab(6)"
                tabindex="0" data-cy="tab-all-status">สิ้นสุด</a>
                <a (click)="selectTab(7)" [ngClass]="{ 'active-tab': selectedTab === 7 }" (keydown.enter)="selectTab(7)"
                tabindex="0" data-cy="tab-all-status">ทั้งหมด</a>
        </div>

        <div *ngIf="selectedTab === 1">
            <div>สถานะ: รอยืนยัน</div>
            <div *ngFor="let order of pending">
                <div (click)="navigateToOrder(order.id)" class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="order-time">{{ order.createdAt | displayDate }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 2">
            <div>สถานะ: ขอยกเลิก</div>
            <div *ngFor="let order of cancelrequested">
                <div (click)="navigateToOrder(order.id)" class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="order-time">{{ order.createdAt | displayDate }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 3">
            <div>สถานะ: ยืนยันแล้ว</div>
            <div *ngFor="let order of confirmed">
                <div (click)="navigateToOrder(order.id)" class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="fs-6 fw-light">
                            {{ order.createdAt | displayDate }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 4">
            <div>สถานะ: ยกเลิก</div>
            <div *ngFor="let order of cancelled">
                <div (click)="navigateToOrder(order.id)" class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="fs-6 fw-light">
                            {{ order.createdAt | displayDate }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 5">
            <div>สถานะ: สำเร็จ</div>
            <div *ngFor="let order of delivered">
                <div class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="fs-6 fw-light">
                            {{ order.createdAt | displayDate }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 6">
            <div>สถานะ: สำเร็จ</div>
            <div *ngFor="let order of deprecated">
                <div class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="fs-6 fw-light">
                            {{ order.createdAt | displayDate }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 7">
            <div>ทั้งหมด</div>
            <div *ngFor="let order of stockistPurchaseOrders">
                <div class="d-flex flex-column mb-2" aria-hidden="true"
                    style="background-color: #fbfbfb; padding: 1.75rem 1rem">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="checklist"></div>
                            <p class="fs-5 fw-light">{{ order.retailerName }}</p>
                        </div>
                        <p class="fs-5 fw-light">
                            {{ order.netAmount | currency : "THB" : "symbol-narrow" }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <p class="fs-6 fw-light">
                            หมายเลขคำสั่งซื้อ {{ order.id }}
                        </p>
                        <p class="fs-6 fw-light">
                            {{ order.createdAt | displayDate }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="status-label" [ngClass]="order.status.toLowerCase()">
                            {{ getStatusLabel(order.status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>