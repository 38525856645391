import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Product,
  IProductsResponse,
} from '@core/models/retailer/product.model';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { APIResponse } from '@core/models/shares/http.model';
import { ListProductStockistResponse, ProductParams } from '@core/models/stockist/product.model';
import { ClearParams } from '@modules/shared/clear-params';
import { StockistsResponse } from '@core/models/stockist/stockist.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProducts(): Observable<APIResponse<IProductsResponse>> {
    return this.http.get<APIResponse<IProductsResponse>>(
      `${environment.apiUrl}/product-management/products`
    );
  }

  getProductById(id: number): Observable<APIResponse<Product>> {
    return this.http.get<APIResponse<Product>>(
      `${environment.apiUrl}/product-management/products/${id}`
    );
  }

  getProductsAvailableWithDataTable(
    params: ProductParams
  ): Observable<ListProductStockistResponse> {
      return this.http.get<ListProductStockistResponse>(
          `${environment.apiProductUrl}/products/available`,
          {
              params: ClearParams(params),
          }
      );
  }

  getStockistsAvailable(): Observable<APIResponse<StockistsResponse>> {
    return this.http.get<APIResponse<StockistsResponse>>(
        `${environment.apiAdminUrl}/stockists/available`
    );
  }
}
