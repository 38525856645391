<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate }}</label>
    <div class="col-md-9 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <ng-select [appendTo]="appendTo" [ngClass]="{ 'is-invalid': isError }" [items]="options$ | async"
    bindLabel="{{ bindLabel }}" bindValue="{{ bindValue }}" [formControl]="form" [hideSelected]="hideSelected"
    [placeholder]="placeholder | translate" [loading]="isLoading" [typeahead]="search$" (change)="onSelect($event)"
    (clear)="onClear()" [searchable]="searchable" [clearable]="clearable" dropdownPosition="auto">
  </ng-select>
  <div class="invalid-feedback">{{ errorMessage}}</div>
</ng-template>