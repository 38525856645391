<head>
    <link href="https://use.fontawesome.com/releases/v5.0.1/css/all.css" rel="stylesheet">
</head>
<div id="myModal" class="modal">
    <div id="animate" class="modal-content">
        <div class="header">
            <span class="">{{ 'REASON_CANCELLATION.TITLE' | translate }}</span>
            <i class="fa-solid fa-xmark" (click)="close()"></i>
        </div>
        <div *ngIf="isLoading" class="overlay">
            <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
        </div>
        <div class="container mt-3">
            <div class="row">
                <div class="overflow-auto">
                    <div class="pt-4 d-flex flex-column align-items-start " style="flex-shrink: 0;">
                        <p class="m-0"
                            style="color: #000; font-family: 'IBM Plex Sans Thai'; font-size: 16px; font-style: normal; font-weight: 500; line-height: 1.4;;">
                            {{ 'REASON_CANCELLATION.REASON' | translate }}
                        </p>
                        <div class="px-3 w-100">
                            <form [formGroup]="reasonForm"> <!-- Add formGroup directive -->
                                <div class="pt-3" *ngFor="let reason of reasons; let i = index">
                                    <div class="d-flex w-100 pt-1 align-items-center">
                                        <div class="col-1 checkbox d-flex align-items-center">
                                            <input [id]="'reason' + i" type="checkbox" [checked]="reasonChecked[i]"
                                                (change)="onReasonChecked(i)" >
                                            <label for="reason{{ i }}" ></label>
                                        </div>

                                        <label [for]="'reason' + i" class="m-0 pl-2"> {{ reason.lc }}</label>
                                        <span *ngIf="i === reasons.length - 1"
                                            class="m-0 text-black-50 bg-transparent pl-2"
                                            style="font-family: 'IBM Plex Sans Thai'; font-size: 16px; font-weight: 400; line-height: 1.4;">
                                            {{ 'REASON_CANCELLATION.SPECIFY' | translate }}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="pt-4">
                        <p class="m-0"
                            style="color: #000; font-family: 'IBM Plex Sans Thai'; font-size: 14px; font-style: normal; font-weight: 400; line-height: 1.4;">
                            {{ 'REASON_CANCELLATION.ADDITIONAL' | translate }}<span *ngIf="isReason5Checked()">*</span>
                        </p>
                        <div class="input-box">
                            <input id="reason" #reasonInput type="text" placeholder="{{ 'REASON_CANCELLATION.SPECIFY_ADDITIONAL' | translate }}" name="reason"
                                [(ngModel)]="otherReason" (blur)="validateReason()" [class.error-input]="showError">
                        </div>
                        <div *ngIf="showError" class="d-flex p-2 align-items-center error-message">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8 2C8.78793 2 9.56815 2.15519 10.2961 2.45672C11.0241 2.75825 11.6855 3.20021 12.2426 3.75736C12.7998 4.31451 13.2417 4.97595 13.5433 5.7039C13.8448 6.43185 14 7.21207 14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2ZM8.007 10.167L7.91 10.175C7.78638 10.1973 7.67567 10.2653 7.5999 10.3655C7.52413 10.4657 7.48885 10.5907 7.50108 10.7157C7.5133 10.8407 7.57214 10.9566 7.66588 11.0402C7.75962 11.1238 7.8814 11.1691 8.007 11.167L8.097 11.159C8.21995 11.1367 8.3301 11.0693 8.40578 10.9698C8.48146 10.8704 8.51717 10.7463 8.50588 10.6218C8.49459 10.4974 8.43712 10.3817 8.34478 10.2975C8.25245 10.2133 8.13195 10.1668 8.007 10.167ZM8 4.833C7.86739 4.833 7.74021 4.88568 7.64645 4.97945C7.55268 5.07321 7.5 5.20039 7.5 5.333V8L7.508 8.09C7.53026 8.21295 7.59775 8.3231 7.69717 8.39878C7.79659 8.47446 7.92074 8.51017 8.04518 8.49888C8.16961 8.48759 8.28531 8.43012 8.36949 8.33778C8.45367 8.24545 8.50023 8.12495 8.5 8V5.333L8.492 5.243C8.47093 5.12789 8.41016 5.02381 8.32026 4.94889C8.23036 4.87397 8.11703 4.83296 8 4.833Z"
                                    fill="#F43A29" />
                            </svg>
                            <p class="text-danger m-0"
                                style="font-family: 'IBM Plex Sans Thai'; font-size: 0.75rem; font-weight: 400; line-height: 1.67; letter-spacing: 0.4px;">
                                {{ 'REASON_CANCELLATION.VALIDATE' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="fixed-bottom p-3">
                    <button (click)="cancelOrder()"
                        class="rounded-3 d-flex p-2 justify-content-center align-items-center gap-2 flex-fill"
                        style="width: 100%;" [disabled]="!isFormValid">
                        {{ 'REASON_CANCELLATION.CONFIRM_CANCEL' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>