import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticParams, DailySummary, DailySummaryParams, MonthlySummary, MonthlySummaryByBrand, MonthlySummaryProductDatatable, MonthlySummaryProductParams } from '@core/models/stockist/monthly-summary.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    baseURL = `${environment.apiUrl}/api/report/stockist`;

    constructor(private http: HttpClient) { }

    getMonthlySummary(params: AnalyticParams): Observable<MonthlySummary> {
        return this.http.get<MonthlySummary>(
            `${this.baseURL}/summary/monthly`,
            {
                params: { month: params.month, year: params.year }
            }
        );
    }

    getDailySummary(params: DailySummaryParams): Observable<DailySummary> {
        return this.http.get<DailySummary>(
            `${this.baseURL}/summary/daily`,
            {
                params: { date: params.date }
            }
        );
    }

    getDailySummaryProduct(params: MonthlySummaryProductParams): Observable<MonthlySummaryProductDatatable> {
        return this.http.get<MonthlySummaryProductDatatable>(
            `${this.baseURL}/summary/monthly/product`,
            {
                params: { month: params.month, year: params.year, limit: params.limit, page: params.page, orderBy: params.orderBy }
            }
        );
    }

    getMonthlySummaryByBrand(params: AnalyticParams): Observable<MonthlySummaryByBrand[]> {
        return this.http.get<MonthlySummaryByBrand[]>(
            `${this.baseURL}/summary/monthly/brand`,
            {
                params: { month: params.month, year: params.year }
            }
        );
    }

    getMonthlySummaryByCategory(params: AnalyticParams): Observable<MonthlySummaryByBrand[]> {
        return this.http.get<MonthlySummaryByBrand[]>(
            `${this.baseURL}/summary/monthly/category`,
            {
                params: { month: params.month, year: params.year }
            }
        );
    }
}
