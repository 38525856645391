<div *ngIf="stockistOrder" style="padding-top: 1.5rem;">
    <nav class="navigate-bar">
        <div class="back-container" (click)="goBack()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_INFO.GO_BACK" | translate }}</label>
        </div>
    </nav>
    <div class="header-section mt-2">
        <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
            <div class="row">
                <div class="fs-sm order-title">{{ "ORDER_INFO.CANCELLED.ORDER_ID" | translate}}</div>
                <div class="fw-bold">{{stockistOrder.id}}</div>
            </div>
            <div class="d-flex justify-items-end">
                <div class="status fw-semibold d-flex flex-column align-items-center">
                    {{ "ORDER_INFO.CANCELLED.STATUS_CANCELLED" | translate}}
                    <div class="status-by d-flex justify-content-center align-items-center">
                        <p>{{stockistOrder.cancelBy === 'Stockist' ? ('ORDER_INFO.CANCELLED.BY_STOCKIST' | translate ) : ('ORDER_INFO.CANCELLED.BY_RETAILER' | translate )}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-3 px-3 text-base font-black">
            {{ "ORDER_INFO.CANCELLED.CANCELLED_AT" | translate}} {{ stockistOrder.cancelledDate | displayDate }}
        </div>
    </div>
    <div class="d-flex flex-column mx-3 pb-2">
        <app-ship-to-address [stockistOrder]="stockistOrder"></app-ship-to-address>
        <app-payment-to-address [stockistOrder]="stockistOrder">
        </app-payment-to-address>
        <!-- <div class="d-flex" [class.more-detail]="!expandDetails" [class.more-detail-active]="expandDetails"
            (click)="expandDetails = !expandDetails">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <img class="detail-icon" [src]="detailIcon" alt="wallet-icon" />
                    รายละเอียดเพิ่มเติม
                </div>
                <i class="fa-regular fa-angle-down down"
                    [style.transform]="expandDetails ? 'rotate(180deg)' : 'none'"></i>
            </div>
            <div *ngIf="expandDetails" class="additional">
                <div *ngFor="let detail of additionalDetails" class="additional-item">
                    <div class="text-xs">{{ detail.label }}</div>
                    <div style="font-weight: 500;">{{ detail.value }}</div>
                </div>
            </div>
        </div> -->
    </div> 
    <div class="mx-3">
        <div class="fw-semibold align-items-end">
            <div class="border-line-top"></div>
            <p>{{ "ORDER_INFO.CANCELLED.PRODUCT_LIST" | translate}}</p>
            <app-show-product-list [products]="stockistOrder.items"></app-show-product-list>
            <p class="fw-normal" *ngIf="orderIsHavePromotionGift">{{ "ORDER_INFO.CANCELLED.GIFT" | translate}}</p>
            <div *ngFor="let promotion of stockistOrder.promotions">
                <app-show-gift-list *ngIf="promotion.promotionType === 'Gift'" [gift]="promotion.giveAway"
                    [quatity]="promotion.quantity"></app-show-gift-list>
            </div>
        </div>

        <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>



        <div *ngIf="stockistOrder.refOrderId && stockistOrder.refOrderId < stockistOrder.id"

            class="my-3 change-order-tab d-flex justify-content-between align-items-center p-2">
            <div class="d-flex align-items-center gap-2">
                <i class="fa-solid fa-circle-exclamation"></i>
                <span class="fs-xs" style="color: #505050;">{{ "ORDER_INFO.CANCELLED.ORDER_CHANGED" | translate}}</span>
            </div>
            <button class="btn-old-order fs-sm fw-md" (click)="navigateToOldOrder()">{{ "ORDER_INFO.CANCELLED.VIEW_OLD_ORDER" | translate}} <i
                    class="fa-solid fa-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
    <!-- <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
        <i class="fa-solid fa-angle-left" style="width: 2.25rem" routerLink="/stockist/orders"></i>
        <div class="navbar-brand">หมายเลขคำสั่งซื้อ {{ orderNumber }}</div>
        <span></span>
    </div>
</nav>
<div class="mx-3">
    <div class=" mb-2">
        <div class="d-flex gap-3 align-items-center">
            <div class="status-label" [ngClass]="stockistOrder.status.toLocaleLowerCase()">
                ยกเลิก
            </div>
            <div class="fw-light mx-2 " data-cy="estimate-delivery-date">
                ยกเลิกเมื่อ
            </div>
        </div>
    </div>
    <div class="d-flex flex-column mt-2">
        <app-ship-to-address
            [name]="stockistOrder.retailerName"
            [address]="stockistOrder.retailerAddress"
        ></app-ship-to-address>

        <hr />

        <div class="row align-items-end">
            <div class="col-6 fw-light">ชื่อผู้ซื้อ</div>
            <div class="col-6 fw-light">เลขประจำตัวผู้เสียภาษีอากร</div>
        </div>
        <div class="row align-items-end mb-2">
            <div class="col-6">
                {{ stockistOrder.retailerName }}
            </div>
            <div class="col-6">
                {{ stockistOrder.taxid }}
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-6 fw-light">เบอร์โทรศัพท์</div>
            <div class="col-6 fw-light">โทรศัพท์มือถือ</div>
        </div>
        <div class="row align-items-end mb-4">
            <div class="col-6">
                {{ stockistOrder.mobilePhoneNumber }}
            </div>
            <div class="col-6">
                <i class="fa-regular fa-phone"></i>
                {{ stockistOrder.mobilePhoneNumber }}
            </div>
        </div>
        <div class="align-items-end fw-light">ที่อยู่สำหรับเรียกเก็บเงิน</div>
        <div class="align-items-end fw-light">
            {{ stockistOrder.retailerAddress }}
        </div>

        <hr />

        <div class="align-items-end mt-4">
            <div class="fs-4">รายการสินค้า</div>
            <app-show-product-list [products]="stockistOrder.items"></app-show-product-list>
        </div>

        <app-payment
            [paymentInfo]="getPaymentInfo()"
        ></app-payment>
    </div>
    <div class="d-flex flex-column gap-3 mb-3">
    </div>
</div> -->