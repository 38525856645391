<div *ngFor="let product of products" class="product-item-share">
    <div *ngIf="product.quantity  >0 || product.quantity.toString() !==''">
        <div class="d-flex gap-2 ">
            <img *ngIf="product.quantity !== 0" (error)="handleImageError($event)" class="img-fit" alt="Product image" width="52px" height="52px" [src]="product.coverImagePathThump"
                [attr.data-cy]="product.id + '-image'" />
            <div *ngIf="product.quantity !== 0" class="product-item">
                <p class="product-name fs-sm" [attr.data-cy]="product.id + '-name'">
                    {{ currentLang === 'th' ? product.productName : product.productNameEN }}
                </p>
                <div class="group-price">
                    <label class="product-name text-base" [attr.data-cy]="product.id + '-quantity'" style="font-weight: 600;">
                        X{{ product.quantity }}
                    </label>
                    <label
                        [class]="product.promotionDiscount ? 'price-discount' : 'price text-base fw-semibold'"
                        [attr.data-cy]="product.id + '-amount'">
                        {{ product.amount | currency : 'THB' : 'symbol-narrow' }}
                    </label>
                    <label *ngIf="product.promotionDiscount" class="price text-base fw-semibold ms-1"
                        [attr.data-cy]="product.id + '-amount'">
                        {{ product.totalAfterPromotionDiscount | currency : 'THB' : 'symbol-narrow' }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>