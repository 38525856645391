import { Component, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CancelOrderPayload } from '@core/models/stockist/confirm-order-cancellation.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reason-cancellation-modal',
  templateUrl: './reason-cancellation-modal.component.html',
  styleUrls: ['./reason-cancellation-modal.component.scss']
})
export class ReasonCancellationModalComponent {
  @ViewChild('reasonInput') reasonInput!: ElementRef<HTMLInputElement>;
  @Input() isOpenModal!: boolean;
  @Input() orderNumber!: string;
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  reasonChecked = [false, false, false, false, false, false];
  isFormValid = false;
  otherReason = '';
  showError = false;
  reason5Checked = false;
  formattedReason = {};
  isLoading = false;

  reasons = [
    { lc: this.translate.instant('REASON_CANCELLATION.LC1') },
    { lc: this.translate.instant('REASON_CANCELLATION.LC2') },
    { lc: this.translate.instant('REASON_CANCELLATION.LC3') },
    { lc: this.translate.instant('REASON_CANCELLATION.LC4') },
    { lc: this.translate.instant('REASON_CANCELLATION.LC5') },
  ];

  reasonForm: FormGroup;

  constructor(private fb: FormBuilder, private purchaseOrderService: StockistService, private router: Router, private spinner: NgxSpinnerService, private translate: TranslateService) {
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    });
  }

  onReasonChecked(index: number) {
    this.reasonChecked[index] = !this.reasonChecked[index];
    if (index === this.reasons.length - 1) {
      this.reason5Checked = !this.reason5Checked
      if (this.reason5Checked && this.reasonInput) {
        this.reasonInput.nativeElement.focus();
      } else {
        this.validateReason();
      }
    }
    this.isFormValid = this.reasonChecked.some(reason => reason !== false);
  }

  validateReason() {
    this.showError = this.otherReason.trim().length === 0 && this.reason5Checked;
    if(this.isReason5Checked()) {
      this.isFormValid = !!this.otherReason
    }
  }

  isReason5Checked() {
    return this.reason5Checked;
  }

  close() {
    this.isOpenModal = false;
    this.isOpenModalChange.emit(this.isOpenModal);
  }

  cancelOrder() {
    const reasons = [];

    // Push selected reasons to the array
    for (let i = 0; i < this.reasonChecked.length; i++) {
      if (this.reasonChecked[i]) {
        reasons.push({ lc: this.reasons[i].lc });
      }
    }

    if (!this.otherReason) {
      this.formattedReason = {
        reason: reasons
      };
    } else {
      this.formattedReason = {
        reason: reasons,
        other: this.otherReason
      };
    }

    const formattedReasonString = JSON.stringify(this.formattedReason);

    const payload:CancelOrderPayload = {
      cancelOrderReason: formattedReasonString, 
    };

    this.isLoading = true;
    this.spinner.show('spinner');
    this.purchaseOrderService
      .cancelOrder(
        this.orderNumber,
        payload 
      ).subscribe({next:() => {
       
          this.router.navigate([`stockist/order/${this.orderNumber}/processed/cancelled`]);
          this.isLoading = false;
          this.spinner.hide('spinner');
        
      },error:(err) => {
        console.error('Error',err)
        this.isLoading = false;
        this.spinner.hide('spinner');
      }
    })
  }
}
