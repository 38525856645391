<ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
    name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
<div class="bg-grey">
    <div class="aura-container-custom pt-4">
        <div class="px-0">
            <div class="back-button justify-content-start pointer-cursor " (click)="goBack()">
                <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
                <span>{{ "PROFILE.GO_BACK" | translate }}</span>
            </div>
        </div>
    </div>

    <div class="d-flex box">
        <div class="flex"><img src="assets/icons/address.svg" /></div>
        <div class="flex">
            <div class="d-flex text">{{ "PROFILE.ADDRESS" | translate }}</div>
            <div class="d-flex title">{{stockistProfile?.name}}</div>
            <div class="d-flex text">{{getAddress()}}</div>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center box" (click)="!linkedLine ? onClickLinkLine() : onClickUnlinkLine()">
        <div class="d-flex align-items-center">
            <img style="height: 24px; padding-right: 8px;" src="assets/images/LINE_Brand_icon.png" alt="line" />
            <div class="ml-2">{{ "PROFILE.BIND_ACCOUNT" | translate }}</div>
        </div>
        <i class="fa-solid fa-arrow-right" *ngIf="!linkedLine" aria-hidden="true"></i>
        <p class="unlinkLine m-0" *ngIf="linkedLine">{{ "PROFILE.CANCEL_BIND_ACCOUNT" | translate }}</p>
    </div>
</div>