<div class="ml-2 pb-2 pt-2">
    <div class="d-flex">
      <img class="map-icon" [src]="mapIcon" alt="map-icon" />
      <div class="d-flex flex-column w-100">
        <div class="text-header fs-sm d-flex justify-content-between align-items-start">
          <div>{{"SHIP_TO_ADDRESS.DELIVERY_TO" | translate}}</div>
          <div class="d-flex">
            <div *ngIf="isShowLocation" class="text-view-map fw-semibold">
              <img class="map-size" [src]="mapImage" alt="map-icon" />
              <div class="fs-6 fw-normal" (click)="navigateToMap(stockistOrder.latitude, stockistOrder.longitude)">
                {{"SHIP_TO_ADDRESS.VIEW_MAP" | translate}}
              </div>
            
            </div>
          </div>
        </div>
        <div class="fs-6 fw-normal text-name">
          {{ stockistOrder.retailerName}}
        </div>
        <div class="fs-sm fw-normal text-address">
          {{ stockistOrder.retailerAddress}}
          {{stockistOrder.retailerSubdistrict}} {{stockistOrder.retailerDistrict}} {{stockistOrder.retailerProvince}}
          {{stockistOrder.retailerPostCode}}
        </div>
      </div>
    </div>
</div>