import { Inject, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

export class CustomDateDashboardAdapter extends NativeDateAdapter {
  constructor( @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,private translate: TranslateService) {
    super(matDateLocale);
    this.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event) => {
    this.setLocale(event.lang);
    });
  }
  override format(date: Date): string {
    const month = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
      'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    const monthEnNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const isThai = this.translate.currentLang === 'th';
    const monthName = isThai ? monthNames[month] : monthEnNames[month];
    const isyear = isThai ? year + 543 : year;

    return `${monthName} ${isyear}`;
  }
}