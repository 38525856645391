<div class="container mt-3">
    <div class="d-flex align-items-center mb-3">
        <button class="close-btn" (click)="navigateToOrderDetail()" tabindex="0">
            <i class="fa-solid fa-angle-left"></i>
        </button>
        <div class="fs-4 mx-auto">ยกเลิกคำสั่งซื้อ {{cancellationInfo.purchaseOrderNumber}}</div>
    </div>
    <div class="fs-5 mb-5">จากผู้ซื้อ {{cancellationInfo.name}}</div>
    <form>
        <p class="fs-6 fw-semibold">กรุณาใส่เหตุผลที่ต้องการยกเลิก*</p>
        <div class="form-check my-3 mx-3" *ngFor="let reason of confirmReasonsText; let i = index">
            <input class="form-check-input" type="checkbox" [id]="'reason' + (i + 1)" (change)="onClickCheckbox(i)">
            <label class="form-check-label" [for]="'reason' + (i + 1)">
                {{reason}} <span class="text-muted" *ngIf="i === confirmReasonsText.length - 1"> โปรดระบุ</span>
            </label>
        </div>
    </form>
    <div class="form-floating mb-3">
        <p for="additionalNote" class="fs-6 fw-semibold">เหตุผลเพิ่มเติม</p>
        <textarea class="form-control placeholder-text-area" id="additionalNote" placeholder="ระบุเหตุผล" style="height: 8rem" [(ngModel)]="otherReason"
            (change)="updateFinalReason()" data-cy="text-other-reason"></textarea>
    </div>
    <div id="footer-page" class="d-flex flex-column fixed-bottom mx-4 mr-4 mb-4">
        <button type="submit" type="button" class="btn mr-4 cancel-order-btn" data-cy="cancel-order-button"
        [disabled]="!isFormValid" (click)="cancelOrder()">ยืนยันยกเลิกคำสั่งซื้อ</button>
    </div>
</div>