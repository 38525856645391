// USA
export const locale = {
    lang: 'th',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },

        LABEL_LIST: {
            NO_DATA: 'ไม่มีข้อมูล',
        },

        PRODUCT: {
            PRODUCT: 'สินค้า',
            BRAND_TITLE: 'แบรนด์',
            PRODUCER_TITLE: 'ผู้ผลิต',
            CATEGORY_TITLE: 'หมวดหมู่',
            CREATE_PRODUCT: 'เพิ่มสินค้า',
            CREATE_BRAND: 'เพิ่มแบรนด์',
            CREATE_PRODUCER: 'เพิ่มผู้ผลิต',
        },

        VALIDATOR: {
            REQUIRED: 'กรุณากรอกข้อมูล',
            MIN: 'กรุณากรอกค่าที่มากกว่า',
            MAX: 'กรุณากรอกค่าที่น้อยกว่านี้',
            EMAIL: 'กรุณาตรวจสอบอีเมล',
            MUST_MATCH: 'รหัสผ่านไม่ตรงกัน',
            PATTERN: 'กรุณาระบุเป็นตัวอักษร (a-z, A-Z, ก-ฮ) เท่านั้น',
            MIN_LENGTH: 'จำนวนตัวอักษรขั้นต่ำ',
            MAX_LENGTH: 'จำนวนตัวอักษรไม่เกิน',
            LENGTH_VAR: 'ตัวอักษร',
            PIN_MUST_MATCH: 'Pin ไม่ตรงกัน',
            DUPLICATE: 'ข้อมูลซ้ำ',
            DUPLICATE_PHONE: 'หมายเลขโทรศัพท์มือถือนี้ถูกใช้ไปแล้ว',
            DUPLICATE_TIME: 'เวลาปิดซ้ำกับเวลาเปิด',
            MIN_LENGTH_PRODUCT: 'จำนวนขั้นต่ำ',
            LENGTH_VAR_PRODUCT: 'หน่วย',
        },

        SIDE_BAR: {
            RETAILER: 'ร้านค้าปลีก',
            STOCKIST: 'ร้านค้าส่ง',
            PRODUCT: 'สินค้า',
            ORDER: 'คำสั่งซื้อ',
            COUPON: 'คูปอง',
            PROMOTION: 'โปรโมชั่น',
            LANGUAGE: 'เปลี่ยนภาษา',
        },

        RETAILER_TITLE: {
            TITLE_ADD: 'เพิ่มร้านค้าปลีก',
            TITLE_UPDATE: 'รายละเอียดร้านค้าปลีก',
        },

        RETAILER: {
            PENDING: 'รอดำเนินการ / ดราฟท์',
            APPROVE: 'อนุมัติ',
            REJECT: 'ปฏิเสธ',
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            STATUS: 'สถานะ',
            PERSION_IN_CHERT: 'ชื่อของผู้รับผิดชอบ',
            INPUT_PERSION_IN_CHERT: 'ผู้รับผิดชอบ',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            SECONDARY_PHONE_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
            EMAIL: 'อีเมล',
            TITLE_STORE: 'ข้อมูลร้านค้า',
            CUSTOMER_TYPE: 'ประเภทลูกค้า',
            STORE_TYPE: 'ประเภทร้านค้า',
            OPEN_HOURS: 'เวลาเปิดให้บริการ',
            HOUSE: 'ชั่วโมง',
            MIN: 'นาที',
            TO: 'ถึง',
            OPEN_DAY: 'วันที่เปิดให้บริการ',
            TITLE_ADDRESS: 'ที่อยู่สำหรับการจัดส่ง',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ป้อนที่อยู่',
            SEARCH_LOCATION: 'ค้นหาตำแหน่ง',
            CURRENT_LOCATION: 'ตำแหน่งปัจจุบัน',
            TITLE_BILLING_ADDRESS: 'ที่อยู่สำหรับออกเอกสารทางการเงิน',
            USE_ADDRESS: 'ใช้ที่อยู่นี้เป็นที่อยู่สำหรับการออกเอกสารทางการเงิน',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            BILLING_ADDRESS: 'ที่อยู่',
            INPUT_BILLING_ADDRESS: 'ป้อนที่อยู่',
            TITLE_DOCUMENT: 'เอกสารเพิ่มเติม',
            ID_CARD: 'บัตรประจำตัวประชาชน (ด้านหน้า)',
            LIQUOR_LICENSE: 'ใบอนุญาตขายสุรา',
            TOBACCO_LICENSE: 'ใบอนุญาตขายบุหรี่',
            STORE_PICTURE: 'รูปภาพหน้าร้าน',
            POR_POR_20: 'ใบ ภ.พ.20',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
            REJECT_CATEGORY: 'ประเภทการปฏิเสธ',
            REJECT_SUBCATEGORY: 'แรงจูงใจ',
            REJECT_REMARK: 'ความคิดเห็น',
        },

        STOCKIST_TITLE: {
            TITLE_ADD: 'เพิ่มร้านค้าส่ง',
            TITLE_UPDATE: 'รายละเอียดร้านค้าส่ง',
        },

        STOCKIST: {
            TITLE_REGISTRANT: 'ข้อมูลการสร้างบัญชี Aura',
            TITLE_REGIST: 'ข้อมูลผู้ลงทะเบียน',
            STATUS: 'สถานะ',
            LOGO: 'โลโก้ร้านค้า',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            BANK_ACCOUNT_NAME: 'ชื่อบัญชีรับเงิน',
            BANK_ACCOUNT_CODE: 'ธนาคารบัญชีรับเงิน',
            BANK_ACCOUNT_NO: 'เลขที่บัญชีรับเงิน',
            OPEN_DAY: 'วันที่เปิดให้บริการ',
            TITLE_ADDRESS: 'ที่ตั้งร้านค้า',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ป้อนที่อยู่',
            SEARCH_LOCATION: 'ค้นหาตำแหน่ง',
            CURRENT_LOCATION: 'ตำแหน่งปัจจุบัน',
            TITLE_BILLING_ADDRESS: 'ที่อยู่สำหรับการเรียกเก็บเงิน',
            USE_ADDRESS: 'ใช้ที่อยู่เดียวกับที่ตั้งร้านค้า',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            BILLING_ADDRESS: 'ที่อยู่',
            INPUT_BILLING_ADDRESS: 'ป้อนที่อยู่',
            TITLE_DOCUMENT: 'เอกสารเพิ่มเติม',
            ID_CARD: 'บัตรประจำตัวประชาชน (ด้านหน้า)',
            LIQUOR_LICENSE: 'ใบอนุญาตขายสุรา',
            TOBACCO_LICENSE: 'ใบอนุญาตขายบุหรี่',
            STORE_PICTURE: 'รูปภาพหน้าร้าน',
            POR_POR_20: 'ใบ ภ.พ.20',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
        },

        RETAILER_HINT: {
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            NAME: 'กรอกชื่อร้านค้า',
            FIRST_NAME: 'กรอกชื่อ',
            INPUT_FIRST_NAME: 'กรอกชื่อที่จดทะเบียน',
            LAST_NAME: 'กรอกนามสกุล',
            TAX_ID: 'กรอกเลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'กรอกหมายเลขโทรศัพท์มือถือ',
            EMAIL: 'กรอกอีเมล',
            CUSTOMER_TYPE: 'เลือกประเภทลูกค้า',
            STORE_TYPE: 'เลือกประเภทร้านค้า',
            POST_CODE: 'กรอกรหัสไปรษณีย์',
            SUB_DISTRICT: 'เลือกตำบล',
            DISTRICT: 'เลือกอำเภอ',
            PROVINCE: 'เลือกจังหวัด',
            ADDRESS: 'กรอกที่อยู่',
            INPUT_ADDRESS: 'กรอกป้อนที่อยู่',
            BILLING_POST_CODE: 'กรอกรหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'กรอกตำบล',
            BILLING_DISTRICT: 'กรอกอำเภอ',
            BILLING_PROVINCE: 'กรอกจังหวัด',
            INPUT_BILLING_ADDRESS: 'กรอกที่อยู่',
        },

        STOCKIST_HINT: {
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            STATUS: 'สถานะ',
            NAME: 'กรอกชื่อร้านค้า',
            FIRST_NAME: 'กรอกชื่อ',
            INPUT_FIRST_NAME: 'กรอกชื่อที่จดทะเบียน',
            LAST_NAME: 'กรอกนามสกุล',
            TAX_ID: 'กรอกเลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'กรอกหมายเลขโทรศัพท์มือถือ',
            EMAIL: 'กรอกอีเมล',
            POST_CODE: 'กรอกรหัสไปรษณีย์',
            SUB_DISTRICT: 'เลือกตำบล',
            DISTRICT: 'เลือกอำเภอ',
            PROVINCE: 'เลือกจังหวัด',
            ADDRESS: 'กรอกที่อยู่',
            INPUT_ADDRESS: 'กรอกป้อนที่อยู่',
            BILLING_POST_CODE: 'กรอกรหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'กรอกตำบล',
            BILLING_DISTRICT: 'กรอกอำเภอ',
            BILLING_PROVINCE: 'กรอกจังหวัด',
            BILLING_ADDRESS: 'กรอกที่อยู่',
            BANK_ACCOUNT_NAME: 'กรอกชื่อบัญชีรับเงิน',
            BANK_ACCOUNT_CODE: 'กรอกธนาคารบัญชีรับเงิน',
            BANK_ACCOUNT_NO: 'กรอกเลขที่บัญชีรับเงิน',
        },

        LIST_RETAILER: {
            RETAILER: 'ร้านค้าปลีก',
            ADD_RETAILER: 'เพิ่มร้านค้าปลีก',
            NAME: 'ชื่อร้านค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            PERSON_CHARGE: 'ผู้รับผิดชอบ',
        },

        RETAILER_STATUS: {
            PENDING: 'รอดำเนินการ',
            CONFIRMED: 'อนุมัติแล้ว',
            REJECTED: 'ถูกปฏิเสธ',
            ALL: 'ทั้งหมด',
            CANCELLED: 'ยกเลิกแล้ว',
        },

        RETAILER_TAB: {
            STORE_INFORMATION: 'ข้อมูลร้านค้า',
            STORE_DOCUMENT: 'เอกสารร้านค้า',
        },

        STOCKIST_TAB: {
            STORE_INFORMATION: 'ข้อมูลร้านค้า',
            STORE_DOCUMENT: 'เอกสารร้านค้า',
            STORE_DERIVERY_ZONE: 'พื้นที่การจัดส่ง',
            STORE_SHELF: 'ชั้นวางสินค้า',
        },

        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: "Don't have an account?",
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            },
        },

        ACTION: {
            BACK_PAGE: 'กลับไปหน้าก่อนหน้า',
            SUBMIT: 'บันทึก',
            CANCEL: 'ยกเลิก',
            PLEASE_SELECT: 'กรุณาเลือก',
            BY: 'โดย',
            SEARCH: 'ค้นหา',
            CLEAR: 'ล้าง',
            CONFIRM: 'ยืนยัน',
        },

        NOTIFICATION: {
            ORDER_NUMBER: 'หมายเลขคำสั่งซื้อ',
        },

        ORDERS: {
            ORDER_ALL: 'ดูคำสั่งซื้อทั้งหมด',
            TOTAL_MONTHLY_AMOUNT: 'ยอดขายในเดือนนี้',
            TOTAL_ORDER: 'จำนวนคำสั่งซื้อ',
            TOTAL_NEW_CUSTOMER: 'ลูกค้าใหม่',
            VIEW_DASHBOARD: 'ดูการวิเคราะห์ทั้งหมด',
            VIEW_ALL: 'ดูทั้งหมด',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            ORDER_AT: 'สั่งซื้อเมื่อ',
            STATUS_PENDING: 'รอยืนยัน',
            STATUS_CONFIRMED: 'ยืนยันแล้ว',
            STATUS_CANCEL_REQUESTED: 'ขอยกเลิก',
            STATUS_CANCELLED: 'ยกเลิก',
            STATUS_DELIVERED: 'จัดส่งสำเร็จ',
            PRODUCT_QUANTITY: 'จำนวนสินค้า',
            ITEM: 'ชิ้น',
            DELIVERY_FEE: 'ค่าจัดส่ง',
            DELIVERED_DATE: 'วันที่จัดส่งได้',
            EMPTY_ORDER: 'ยังไม่มีคำสั่งซื้อ',
            PRODUCT_TITLE: 'สินค้า'
        },

        DASHBOARD: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            TITLE_DAY: 'รายวัน',
            TITLE_MONTH: 'รายเดือน',
            TITLE_ORDER: 'สินค้า',
            ORDER_ALL: 'คำสั่งซื้อทั้งหมด',
            STATUS_PENDING: 'รอยืนยัน',
            STATUS_CONFIRMED: 'ยืนยันแล้ว',
            STATUS_CANCEL_REQUEST: 'ขอยกเลิก',
            STATUS_CANCEL_BY_RETAILER: 'ยกเลิกโดยร้านค้าปลีก',
            STATUS_CANCEL_BY_STOCKIST: 'ยกเลิกโดยร้านค้าส่ง',
            STATUS_DELIVERED: 'จัดส่งสำเร็จ',
            TOTAL_MONTHLY_AMOUNT: 'ยอดขายในเดือนนี้',
            TOTAL_ORDER: 'จำนวนคำสั่งซื้อทั้งหมด',
            TOTAL_ORDER_DELIVERED: 'คำสั่งซื้อที่จัดส่งสำเร็จ',
            TOTAL_ORDER_CANCEL_BY_STOCKIST:
                'คำสั่งซื้อที่ถูกยกเลิกโดยร้านค้าส่ง',
            TOTAL_ORDER_CANCEL_BY_RETAILER:
                'คำสั่งซื้อที่ถูกยกเลิกโดยร้านค้าปลีก',
            TOTAL_NEW_CUSTOMER: 'ลูกค้าใหม่',
            TOTAL_OLD_CUSTOMER: 'ลูกค้าเก่าที่ซื้อซ้ำ',
            PROGRESSIVE: 'เพิ่มขึ้น',
            REDUCE: 'ลดลง',
            UNALTERED: 'ไม่เปลี่ยนแปลง',
            QUANTITY: 'จำนวน',
            PRODUCT_IMAGE: 'รูปสินค้า',
            PRODUCT_NAME: 'ชื่อสินค้า',
            BY_BRAND: 'โดยแบรนด์',
            BY_CATEGORY: 'โดยหมวดหมู่',
            UNIT_DESC: 'เรียงจาก จำนวน : มาก - น้อย',
            AMOUNT_DESC: 'เรียงจาก ยอดขาย : มาก - น้อย',
            UNIT_ASC: 'เรียงจาก จำนวน : น้อย - มาก',
            AMOUNT_ASC: 'เรียงจาก ยอดขาย : น้อย - มาก',
            COUPON: 'คูปอง',
        },

        CHARTS: {
            UNIT: 'หน่วย',
            AMOUNT: 'ยอดขาย',
            QUANTITY: 'จำนวน',
        },

        ORDER_HISTORY: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            TOTAL_ORDER: 'จำนวนคำสั่งซื้อทั้งหมด',
            TOTAL_ORDER_PENDING: 'จำนวนคำสั่งซื้อที่รอยืนยัน',
            TOTAL_ORDER_CONFIRMED: 'จำนวนคำสั่งซื้อที่ยืนยันแล้ว',
            TOTAL_ORDER_CANCEL_REQUESTED: 'จำนวนคำสั่งซื้อที่ขอยกเลิก',
            TOTAL_ORDER_CANCELLED: 'จำนวนคำสั่งซื้อที่ยกเลิกแล้ว',
            TOTAL_ORDER_DELIVERED: 'จำนวนคำสั่งซื้อที่จัดส่งสำเร็จแล้ว',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            ORDER_AT: 'สั่งซื้อเมื่อ',
            STATUS_PENDING: 'รอยืนยัน',
            STATUS_CONFIRMED: 'ยืนยันแล้ว',
            STATUS_CANCEL_REQUESTED: 'ขอยกเลิก',
            STATUS_CANCELLED: 'ยกเลิก',
            STATUS_DELIVERED: 'จัดส่งสำเร็จ',
            PRODUCT_QUANTITY: 'จำนวนสินค้า',
            STATUS_CANCEL_BY_RETAILER: 'ยกเลิกโดยร้านค้าปลีก',
            STATUS_CANCEL_BY_STOCKIST: 'ยกเลิกโดยร้านค้าส่ง',
            ITEM: 'ชิ้น',
            DELIVERY_FEE: 'ค่าจัดส่ง',
            DELIVERED_DATE: 'วันที่จัดส่งได้',
            EMPTY_ORDER: 'ยังไม่มีคำสั่งซื้อ',
        },

        ORDER_INFO: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            PENDING: {
                ORDER_ID: 'หมายเลขคำสั่งซื้อ',
                STATUS_PENDING: 'รอยืนยัน',
                ORDER_AT: 'สั่งซื้อเมื่อ',
                ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
                PRODUCT_LIST: 'รายการสินค้า',
                GIFT: 'ของแถม',
                CHECK_ORDER: 'ตรวจสอบคำสั่งซื้อ',
                USER_NAME: 'ชื่อผู้ซื้อ',
                TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
                PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
                SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
            },

            CONFIRMED: {
                ORDER_ID: 'หมายเลขคำสั่งซื้อ',
                STATUS_CONFIRMED: 'ยืนยันแล้ว',
                CONFIRMED_AT: 'ยืนยันเมื่อ',
                NO_APPROVED: 'ไม่อนุมัติคำขอยกเลิกเมื่อ',
                ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
                PRODUCT_LIST: 'รายการสินค้า',
                GIFT: 'ของแถม',
                ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
                VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
                DELIVERED: 'จัดส่งสินค้าแล้ว',
                CANCEL_ORDER: 'ยกเลิกคำสั่งซื้อ',
                USER_NAME: 'ชื่อผู้ซื้อ',
                TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
                PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
                SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
                MODAL: {
                    TITLE: 'มีการเปลี่ยนแปลงจำนวนสินค้า',
                    MESSAGE: 'คุณได้เปลี่ยนแปลงข้อมูลสินค้าบางส่วน\nหมายเลขคำสั่งซื้อจะถูกเปลี่ยนแปลงเมื่อยืนยันข้อมูล',
                    CONFIRM: 'ยืนยัน',
                    CANCEL: 'ยกเลิก',
                }
            },

            CANCEL_REQUESTED: {
                ORDER_ID: 'หมายเลขคำสั่งซื้อ',
                STATUS_CANCEL_REQUESTED: 'ขอยกเลิก',
                CANCEL_REQUESTED_AT: 'ขอยกเลิกเมื่อ',
                ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
                PRODUCT_LIST: 'รายการสินค้า',
                GIFT: 'ของแถม',
                ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
                VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
                APPROVED_REQUEST: 'อนุมัติคำขอ',
                NOT_APPROVED: 'ไม่อนุมัติ',
                USER_NAME: 'ชื่อผู้ซื้อ',
                TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
                PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
                SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
            },

            CANCELLED: {
                ORDER_ID: 'หมายเลขคำสั่งซื้อ',
                STATUS_CANCELLED: 'ยกเลิก',
                CANCELLED_AT: 'ยกเลิกเมื่อ',
                BY_STOCKIST: 'โดยร้านค้าส่ง',
                BY_RETAILER: 'โดยร้านค้าปลีก',
                PRODUCT_LIST: 'รายการสินค้า',
                GIFT: 'ของแถม',
                ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
                VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
            },

            DELIVERED: {
                ORDER_ID: 'หมายเลขคำสั่งซื้อ',
                STATUS_DELIVERED: 'จัดส่งสำเร็จ',
                DELIVERED_AT: 'จัดส่งเมื่อ',
                PRODUCT_LIST: 'รายการสินค้า',
                GIFT: 'ของแถม',
                ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
                VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
            },
        },

        SHIP_TO_ADDRESS: {
            DELIVERY_TO: 'จัดส่งที่',
            VIEW_MAP: 'ดูแผนที่',
        },

        PAYMENT_TO_ADDRESS: {
            BILLING_ADDRESS: 'ที่อยู่สำหรับการเรียกเก็บเงิน',
        },

        PAYMENT: {
            TOTAL_PRODUCT_PRICE: 'ยอดรวมสินค้าทั้งหมด',
            ITEM: 'ชิ้น',
            PROMOTION_DISCOUNT: 'ส่วนลดโปรโมชัน',
            COUPON_DISCOUNT: 'ส่วนลดคูปอง',
            DELIVERY_FEE: 'ค่าจัดส่ง',
            TOTAL: 'ยอดรวมสุทธิ',
        },

        CONFIRM_ORDER: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            CHECK_ORDER: 'ตรวจสอบคำสั่งซื้อ',
            WARNING:
                'หากคุณไม่สามารถจัดส่งสินค้าได้ครบทุกรายการคุณสามารถแก้ไขจำนวนสินค้าได้ (หมายเลขคำสั่งซื้อจะถูกเปลี่ยนแปลงหลังจากยืนยัน)',
            PRODUCT_LIST: 'รายการสินค้า',
            RESTORE_ORDER: 'คืนค่าคำสั่งซื้อ',
            STOCKIST_OPEN: 'วันที่ร้านค้าเปิดบริการ',
            EXPECTED_DELIVERY_ORDER: 'วันที่คาดว่าจะจัดส่งสินค้า',
            EXPECTED_DELIVERY: 'วันที่คาดว่าจะจัดส่ง',
            VALIDATE: 'กรุณาจัดส่งสินค้าโดยเร็วที่สุด ไม่เกิน 3 วันทำการ',
            CONFIRM_ORDER: 'ยืนยันคำสั่งซื้อ',
            CANCEL_ORDER: 'ยกเลิกคำสั่งซื้อ',
        },

        PROCESSED_CONFIRMED: {
            CONFIRM_ORDER: 'ยืนยันคำสั่งซื้อสำเร็จ',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            CONFIRMED_AT: 'ยืนยันเมื่อ',
            EXPECTED_DELIVERY: 'วันที่คาดว่าจะจัดส่ง',
            GIFT: 'ของแถม',
            VIEW_ORDER_DETAIL: 'ดูรายละเอียดคำสั่งซื้อ',
            BACK_TO_HOME: 'กลับไปหน้าหลัก',
        },

        PROCESSED_CANCELLED: {
            CANCELLED_ORDER: 'คำสั่งซื้อถูกยกเลิกแล้ว',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            CANCELLED_AT: 'ยกลิกเมื่อ',
            GIFT: 'ของแถม',
            VIEW_ORDER_DETAIL: 'ดูรายละเอียดคำสั่งซื้อ',
            BACK_TO_HOME: 'กลับไปหน้าหลัก',
        },

        PROCESSED_REJECTED: {
            REJECT_CANCEL_REQUEST: 'ไม่อนุมัติคำขอยกเลิก',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            REJECT_CANCEL_REQUEST_AT: 'ไม่อนุมัติคำขอยกเลิกเมื่อ',
            GIFT: 'ของแถม',
            VIEW_ORDER_DETAIL: 'ดูรายละเอียดคำสั่งซื้อ',
            BACK_TO_HOME: 'กลับไปหน้าหลัก',
        },

        DELIVERED_DATE: {
            EXPECTED_DELIVERY: 'วันที่คาดว่าจะจัดส่ง',
            VALIDATE:
                'ออเดอร์มีการล่าช้าจากวันที่คาดว่าจะจัดส่ง กรุณาทำการจัดส่ง',
        },

        MY_NOTIFICATION: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            NO_NOTIFICATION: 'ยังไม่มีข้อความใหม่',
            NEW_NOTIFICATION:
                'มีข้อความอัพเดตข่าวสารที่น่าสนใจที่นี่เร็ว ๆ นี้!ใหม่',
        },

        NEW_SIDE_BAR: {
            PROFILE: 'ข้อมูลส่วนตัว',
            CONTACT: 'ติดต่อเรา',
            CHAT: 'แชทกับศูนย์บริการลูกค้า',
            CALL: 'โทรติดต่อศูนย์บริการลูกค้า',
            BUSINESS: 'เวลาทำการ 08:00 น.- 20:00 น.',
            LOGOUT: 'ออกจากระบบ',
        },

        LOGIN: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            TITLE: 'ยินดีต้อนรับสู่',
            TITLE_SUB: 'เข้าสู่ระบบและจัดการหน้าร้านได้เลย',
            COUNTY: 'ประเทศ',
            ENTER_PHONE: 'กรอกหมายเลขโทรศัพท์มือถือ',
            PHONE: 'หมายเลขโทรศัพท์มือถือ',
            ERROR_NETWORK: 'กรุณาเชื่อมต่ออินเตอร์เน็ต',
            ERROR_PHONE: 'ไม่พบบัญชีผู้ใช้งาน',
            ERROR_PHONE_1: 'หมายเลขโทรศัพท์มือถือไม่ถูกต้อง',
            ERROR_PHONE_2: 'กรุณากรอกหมายเลขโทรศัพท์',
            FOOTER: 'ยังไม่มีบัญชีกับ',
            FOOTER_1: 'ใช่หรือไม่?',
            REGISTER: 'ลงทะเบียน',
            TITLE_PASSWORD: 'ใส่รหัสผ่าน',
            TITLE_PASSWORD_2: 'ใส่รหัสผ่านสำหรับการเข้าใช้งาน',
            PASSWORD: 'รหัสผ่าน',
            INPUT_PASSWORD: 'กรอกรหัสผ่าน',
            ERROR_PASSWORD_1: 'กรุณากรอกรหัสผ่าน',
            ERROR_PASSWORD_2: 'รหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบรหัสผ่านอีกครั้ง',
            NEXT: 'ถัดไป',
            FORGET_PASSWORD: 'ลืมรหัสผ่านใช่ไหม?',
            RESET_PASSWORD: 'รีเซ็ตรหัสผ่าน',
            OTP_TITLE_1: 'ส่งรหัส OTP ไปยังหมายเลขโทรศัพท์มือถือ',
            OTP_TITLE_2: 'รหัส OTP ของคุณจะหมดอายุภายใน 5 นาที',
            ENTER: 'ใส่รหัส OTP',
            ERROR_OTP: 'ไม่ได้รับรหัส OTP?',
            ERROR_OTP_1: 'กรุณากรอก OTP',
            ERROR_OTP_2: 'รหัส OTP ไม่ถูกต้อง',
            SEND_OTP: 'ส่งรหัส OTP อีกครั้ง',
            ERROR_SET_PASSWORD_1: 'กรุณากรอกรหัสผ่านและยืนยันรหัสผ่าน',
            ERROR_SET_PASSWORD_2: 'กรุณากรอกยืนยันรหัสผ่าน',
            ERROR_SET_PASSWORD_3: 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
            ERROR_SET_PASSWORD_4: 'กรุณากรอกรหัสผ่าน 8 ตัวขึ้นไป',
            ERROR_SET_PASSWORD_5:
                'รหัสผ่านต้องเป็นภาษาอังกฤษและมีความยาวอย่างน้อย 8 ตัวอักษร ตัวพิมพ์ใหญ่หนึ่งตัว, ตัวพิมพ์เล็กหนึ่งตัว และตัวเลขหนึ่งตัว',
            ERROR_SET_PASSWORD_6: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
            ERROR_SET_PASSWORD_7:
                'รหัสผ่านต้องเป็นภาษาอังกฤษหรือตัวเลข อย่างน้อย 8 ตัวอักษร',
            CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
            SET_PASSWORD: 'ตั้งรหัสผ่าน',
            SET_PASSWORD_LOGIN: 'ตั้งรหัสผ่านสำหรับการเข้าใช้งาน',
            CONFIRM: 'ยืนยัน',
            SET_NEW_PASSWORD: 'ตั้งรหัสผ่านใหม่',
        },

        PROFILE: {
            GO_BACK: 'กลับไปหน้าก่อนหน้า',
            ADDRESS: 'ที่อยู่',
            BIND_ACCOUNT: 'ผูกบัญชี',
            CANCEL_BIND_ACCOUNT: 'ยกเลิกการผูกบัญชี',
            SUBDISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            TITLE: 'ต้องการยกเลิกการผูกบัญชี?',
            MESSAGE:
                'เมื่อยืนยันการยกเลิกผูกบัญชี\n คุณจะไม่ได้รับการแจ้งเตือนกิจกรรมที่เกิดขึ้นบน\n Wabi2b-TH เช่น การแจ้งเตือนคำสั่งซื้อ\nหรืออื่น ๆ ที่มีลักณะคล้ายกันบน\nช่องทางแชทไลน์ \nยืนยันการยกเลิกการผูกบัญชี?',
            CONFIRM: 'ยืนยัน',
            CANCEL: 'ยกเลิก',
        },

        REASON_CANCELLATION: {
            TITLE: 'เหตุผลยกเลิกคำสั่งซื้อ',
            REASON: 'กรุณาใส่เหตุผลที่ต้องการยกเลิก',
            SPECIFY: 'โปรดระบุ',
            ADDITIONAL: 'เหตุผลเพิ่มเติม',
            SPECIFY_ADDITIONAL: 'ระบุเหตุผล',
            VALIDATE: 'หากเลือก อื่นๆ คุณจำเป็นต้องระบุเหตุผลเพิ่มเติมด้วย',
            CONFIRM_CANCEL: 'ยืนยันยกเลิกคำสั่งซื้อ',
            LC1: 'สินค้าหมด',
            LC2: 'ฉันต้องการแก้ไขรายการสินค้า',
            LC3: 'มีการเปลี่ยนแปลงราคาสินค้า',
            LC4: 'ไม่สามารถทำการจัดส่งสินค้าได้',
            LC5: 'อื่นๆ',
        },
        SHELF_MANAGEMENT: {
            UNIT_PRICE: 'ราคาต่อหน่วย',
            MINIMUM_UNIT: 'จำนวนขั้นต่ำ',
            MAXIMUM_UNIT: 'จำนวนสูงสุด',
            DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
            UPLOAD_FILE: 'อัพโหลดไฟล์',
            UPLOAD_FILE_SUCCESS: 'อัพโหลดไฟล์เสร็จสิ้น',
            TITLE: 'แก้ไขรายละเอียดสินค้า',
            VIEW_TITLE: 'รายละเอียดสินค้า',
            ADD_FILE: 'เพิ่มไฟล์',
            FILE_DETAIL: 'กรุณาอัพโหลดไฟล์ xls,xlsx ขนาดไม่เกิน 100 MB',
            ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
            ERROR_UPLOAD: 'อัพโหลดไฟล์ไม่สำเร็จ กรุณาเช็คความถูกต้องและลองใหม่อีกครั้ง',
            ERROR_EAN: 'ตรวจพบ EAN ซ้ำ กรุณาเช็คความถูกต้องและลองใหม่อีกครั้ง',
            LIST_TITLE: 'ชั้นวางสินค้า',
            PRODUCT_UNIT: 'รายการ',
            FILTER_TITLE: 'ตัวกรอง',
            TAB_STATUS : {
                ALL: 'ทั้งหมด',
                ACTIVE: 'เปิดการใช้งาน',
                INACTIVE: 'ปิดการใช้งาน'
            },
            EMPTY_SHELF_TITLE: 'ไม่มีสินค้าในชั้นวางสินค้า',
            EMPTY_SHELF_DESCRIPTION: 'คุณยังไม่ได้เพิ่มสินค้าในชั้นวางสินค้า'
        },

        FILTER: {
          FILTER: 'ตัวกรอง',
          CATEGORY: 'หมวดหมู่',
          BRAND: 'แบรนด์',
          SORT_BY: 'เรียงตาม',
          NEW: 'สินค้า : ใหม่-เก่า',
          OLD: 'สินค้า : เก่า-ใหม่',
          LOW: 'ราคา : ต่ำ-สูง',
          HIGH: 'ราคา : สูง-ต่ำ'
        },

        LIST_SHELF_MANAGEMENT: {
            PRODUCT: 'สินค้า',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            NAME: 'ชื่อสินค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            DELIVERY_ZONE: 'พื้นที่การจัดส่ง',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            COVERIMAGE: 'รูปสินค้า',
            BRANDNAME: 'ชื่อแบรนด์',
            CATEGORY: 'หมวดหมู่',
            UNIT_PRICE: 'ราคาต่อหน่วย',
            MODAL_TITTLE: 'ยืนยันปิดการใช้งานสินค้า',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานสินค้านี้ใช่หรือไม่ ?',
            MODAL_CONFIRM_LABEL: 'ปิดการใช้งาน',
            MODAL_CANCEL_LABEL: 'ยกเลิก'
        },

        MODAL_SHELF: {
          TITLE: 'ยืนยันการปิดการใช้งาน',
          MESSAGE: 'คุณต้องการที่จะปิดการใช้งานสินค้านี้ชั่วคราวใช่หรือไม่ ?',
          CONFIRM: 'ปิดการใช้งาน',
          CANCEL: 'ยกเลิก'
        }

    },
};
