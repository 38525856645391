import { Component, Input, OnInit } from '@angular/core';
import { IOrderItemDetail } from '@core/models/stockist/order.model';
import { PurchaseOrderItem } from '@core/models/stockist/preview-order.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-product-list',
  templateUrl: './show-product-list.component.html',
  styleUrls: ['./show-product-list.component.scss'],
})
export class ShowProductListComponent implements OnInit {
  @Input() products: PurchaseOrderItem[] | IOrderItemDetail[] = [];
  currentLang = 'th'

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
  }
  handleImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
  }
}
