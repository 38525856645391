import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile, LineUrlResponse } from '@core/models/stockist/profile.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private http: HttpClient) { }

    getProfile(): Observable<Profile> {
        return this.http.get<Profile>(
            `${environment.stockistAPI}/stockists/profile`
        );
    }

    getLinkAuth(): Observable<LineUrlResponse> {
        return this.http.get<LineUrlResponse>(
            `${environment.stockistAPI}/line/request-login/stockist`
        );
    }

    unlinkLine(): Observable<void> {
        return this.http.delete<void>(
            `${environment.stockistAPI}/line/unlink/stockist`
        );
    }
}
