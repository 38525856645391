<div *ngIf="stockistOrder">
    <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
            <i class="fa-solid fa-angle-left" style="width: 2.25rem" onclick="navigateToOrderHistory()"></i>
            <div class="navbar-brand">หมายเลขคำสั่งซื้อ {{stockistOrder.id}}</div>
            <span></span>
        </div>
    </nav>
    <div class="mx-3">
        <div class=" mb-2">
            <div class="d-flex gap-3 align-items-center">
                <div class="status-label" [ngClass]="stockistOrder.status.toLocaleLowerCase()">
                    ยกเลิก
                </div>
                <div class="fw-light mx-2 " data-cy="estimate-delivery-date">
                    ยกเลิกเมื่อ
                    <!-- {{ this.stockistOrder.cancelledDate! | displayDate }} -->
                </div>
            </div>
        </div>
        <div class="d-flex flex-column mt-2">
            <div class="d-flex justify-content-start fw-light">จัดส่งที่</div>
            <div class="d-flex justify-content-between align-items-end">
                <div class="fs-5 fw-normal">
                    {{ stockistOrder.retailerName }}
                </div>
                <div class="text-view-map fw-semibold">
                    <img class="map-size" [src]="iconMap" alt="map-icon" />
                    ดูแผนที่
                </div>
            </div>
            <div class="fw-light mb-2">
                {{ stockistOrder.retailerAddress }}
            </div>
            <hr />
            <div class="row align-items-end">
                <div class="col-6 fw-light">ชื่อผู้ซื้อ</div>
                <div class="col-6 fw-light">เลขประจำตัวผู้เสียภาษีอากร</div>
            </div>
            <div class="row align-items-end mb-2">
                <div class="col-6">
                    {{ stockistOrder.retailerName }}
                </div>
                <div class="col-6">
                    {{ stockistOrder.taxid }}
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-6 fw-light">เบอร์โทรศัพท์</div>
                <div class="col-6 fw-light">โทรศัพท์มือถือ</div>
            </div>
            <div class="row align-items-end mb-4">
                <div class="col-6">
                    {{ stockistOrder.mobilePhoneNumber }}
                </div>
                <div class="col-6">
                    <i class="fa-regular fa-phone"></i>
                    {{ stockistOrder.mobilePhoneNumber }}
                </div>
            </div>
            <div class="align-items-end fw-light">ที่อยู่สำหรับการเรียกเก็บเงิน</div>
            <div class="align-items-end fw-light">
                {{ stockistOrder.retailerAddress }}
            </div>
            <hr />
            <div class="align-items-end mt-4">
                <div class="fs-4">รายการสินค้า</div>
                <app-show-product-list [products]="stockistOrder.items"></app-show-product-list>
                <p class="fw-normal" *ngIf="orderIsHavePromotionGift">ของแถม</p>
                <div *ngFor="let promotion of stockistOrder.promotions">
                    <app-show-gift-list *ngIf="promotion.promotionType === 'Gift'" [gift]="promotion.giveAway"
                        [quatity]="promotion.quantity"></app-show-gift-list>
                </div>
            </div>
            <div class="align-items-end mt-2">
                <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
            </div>
        </div>
        <div class="d-flex flex-column gap-3 mb-3">
        </div>
    </div>
</div>
