import { Component, OnInit, OnChanges, SimpleChanges, Input, ChangeDetectorRef } from '@angular/core';
import { ApexChart, ApexTitleSubtitle, ApexPlotOptions, ApexTooltip, ApexGrid, ApexLegend, ApexFill } from 'ng-apexcharts';
import { MonthlySummaryByBrand } from '@core/models/stockist/monthly-summary.model';
import { TranslateService } from '@ngx-translate/core';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any[];
  labels?: {
    show?: boolean;
    style?: {
      colors?: string[];
      fontSize?: string;
    };
  };
};

type ApexYAxis = {
  labels?: {
    show?: boolean;
  };
};

type ChartDataPoint = {
  x: string;
  y: number;
  z: number;
  fillColor: string;
};

export type ChartOptions = {
  series: {
    name: string;
    data: ChartDataPoint[];
  }[];
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  tooltip: ApexTooltip;
  grid: ApexGrid;
  legend: ApexLegend;
  dataLabels: {
    enabled: boolean;
  };
  fill: ApexFill;
};

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnChanges {
  @Input() brandData: MonthlySummaryByBrand[] = [];
  @Input() showByType = "";

  public chartOptions: ChartOptions;
  currentLang!: string;

  predefinedColors: string[] = [
    "#139A4B",
    "#21C063",
    "#93F9BC",
    "#B9F4D1",
    "#DFFBEA"
  ];

  constructor(private cdr: ChangeDetectorRef, private translate: TranslateService,) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: 'bar',
        offsetY: 0,
        sparkline: {
          enabled: true
        }
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [],
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        colors: this.predefinedColors,
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
          const color = dataPoint.fillColor;
          const Unit = this.translate.instant('CHARTS.UNIT');
          const Amount = this.translate.instant('CHARTS.AMOUNT');
          const Quantity = this.translate.instant('CHARTS.QUANTITY');
          if (this.showByType === "Amount") {
            return `
            <div class="box" style="gap:10px">
              <div class="inner-box" style="padding:10px">
                <span>
                  <div style="background-color: ${color}; width: 12px; height: 12px; display: inline-block; margin-right: 5px;border-radius: 50%;"></div>
                  ${w.globals.labels[dataPointIndex]}
                </span>
                <div>${Amount} : ${this.formatNumberPoint(dataPoint.y)} ฿</div>
                <div>${Quantity} : ${this.formatNumber(dataPoint.z)} ${Unit}</div>
              </div>
            </div>`;
          } else {
          return `
            <div class="box" style="gap:10px">
              <div class="inner-box" style="padding:10px">
                <span>
                  <div style="background-color: ${color}; width: 12px; height: 12px; display: inline-block; margin-right: 5px;border-radius: 50%;"></div>
                  ${w.globals.labels[dataPointIndex]}
                </span>
                <div>${Quantity} : ${this.formatNumber(dataPoint.y)} ${Unit}</div>
                <div>${Amount} : ${this.formatNumberPoint(dataPoint.z)} ฿</div>
              </div>
            </div>`;
          }
        }
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
      },
    };
  }

  ngOnInit(): void {
    this.updateChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandData'] && !changes['brandData'].isFirstChange()) {
      this.updateChartOptions();
      this.cdr.detectChanges();
    }
  }

  updateChartOptions(): void {
    this.currentLang = this.translate.currentLang;
    const categories = this.brandData.map(brand => this.currentLang === 'th' ? brand.title : brand.titleEN);
    let totalAmounts: ChartDataPoint[] = [];

    if (this.showByType === "Amount") {
      totalAmounts = this.brandData.map((brand, index) => ({
        x: brand.title || brand.titleEN,
        y: brand.totalAmount,
        z: brand.totalQuantity,
        fillColor: this.predefinedColors[index % this.predefinedColors.length]
      }));
    }

    if (this.showByType === "Quantity") {
      totalAmounts = this.brandData.map((brand, index) => ({
        x: brand.title || brand.titleEN,
        y: brand.totalQuantity,
        z: brand.totalAmount,
        fillColor: this.predefinedColors[index % this.predefinedColors.length]
      }));
    }

    const seriesData = [
      {
        name: this.showByType === "Amount" ? 'Total Amount' : 'Total Quantity',
        data: totalAmounts
      }
    ];

    const dynamicWidth = this.brandData.length * 80;
    const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

    this.chartOptions = {
      ...this.chartOptions,
      series: seriesData,
      chart: {
        ...this.chartOptions.chart,
        width: chartWidth
      },
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: categories
      }
    };

    this.cdr.detectChanges();
  }

  formatNumberPoint(value: number, locale = 'en-US'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }

  formatNumber(value: number, locale = 'en-US'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }
}