/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { MonthlySummaryProductParams, DailySummary, DailySummaryParams, MonthlySummary, AnalyticParams, MonthlySummaryProductDatatable, MonthlySummaryByBrand } from '@core/models/stockist/monthly-summary.model';
import { ReportService } from '@core/services/stockist/report.service';
import { APP_DATE_FORMATS } from '@modules/shared/_config/date-format-th';
import { DatatabelColumn } from '@modules/shared/datatable/interfaces/datatable.interface';
import { CustomDateDashboardAdapter } from '../../../../../../_helpers/custom-datepicker-dashboard';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateDashboardAdapter, deps: [MAT_DATE_LOCALE, TranslateService] },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class DashboardComponent implements OnInit {
  dataColumn: DatatabelColumn[] = [];
  dailySummary?: DailySummary;
  monthlySummaryProduct?: MonthlySummaryProductDatatable;
  @ViewChild('productNameTemplate', { static: true })
  cellTemplateProductName?: TemplateRef<any>;
  @ViewChild('unitAmountTemplate', { static: true })
  cellTemplateUnitAmount?: TemplateRef<any>;
  @ViewChild('dp') picker: MatDatepicker<any> | undefined;
  readonly date = new FormControl(moment());
  dataSource: any[] = [];
  currentLang! : string

  constructor(
    private router: Router,
    private reportService: ReportService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
) {}

loading_data = true
brandData: MonthlySummaryByBrand[] = []
showByType = ""

predefinedColors: string[] = [
  "#139A4B",
  "#21C063",
  "#93F9BC",
  "#B9F4D1",
  "#DFFBEA"
];

sortingOptions = [
  { value: 'TotalUnit_desc', label: this.translate.instant('DASHBOARD.UNIT_DESC') },
  { value: 'TotalAmount_desc', label: this.translate.instant('DASHBOARD.AMOUNT_DESC') },
  { value: 'TotalUnit_asc', label: this.translate.instant('DASHBOARD.UNIT_ASC') },
  { value: 'TotalAmount_asc', label: this.translate.instant('DASHBOARD.AMOUNT_ASC') }
];
selectedSortOption = 'TotalUnit_desc';

sortingOptionsByType = [
  { value: 'brand', label: this.translate.instant('DASHBOARD.BY_BRAND'), },
  { value: 'category', label: this.translate.instant('DASHBOARD.BY_CATEGORY'), }
];
selectedsortingOptionByType = 'brand';

dataSummary: MonthlySummary = {
  totalOrderAmount: 0,
  totalOrderAmountChangePercent: 0,
  totalOrder: 0,
  totalOrderChangePercent: 0,
  confirmedOrder: 0,
  confirmedOrderChangePercent: 0,
  cancelledOrderByRetailer: 0,
  cancelledOrderByRetailerChangePercent: 0,
  cancelledOrderByStockist: 0,
  cancelledOrderByStockistChangePercent: 0,
  newCustomer: 0,
  newCustomerChangePercent: 0,
  returningCustomer: 0,
  returningCustomerChangePercent: 0,
  totalFeeAmount: 0,
  totalCouponAmount: 0,
};

paramsMonth: AnalyticParams = {
  month: moment().month() + 1,
  year: moment().year(),
}

paramsMonthProduct: MonthlySummaryProductParams = {
  month: moment().month() + 1,
  year: moment().year(),
  limit: 10,
  page: 1,
  orderBy: 'TotalUnit_desc',
}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.newDate();
    this.dataColumn = [
      {
        id: 'name',
        title: this.translate.instant('DASHBOARD.PRODUCT_NAME'),
        sortdisable: true,
        cellTemplate: this.cellTemplateProductName
      },
      {
        id: 'totalAmount',
        title: this.translate.instant('DASHBOARD.QUANTITY'),
        sortdisable: true,
        cellTemplate: this.cellTemplateUnitAmount
      },
    ];
    this.openDatepicker();
  }

  openDatepicker(): void {
    setTimeout(() => {
      this.picker?._selectMonth(moment().toDate());
    });
  }

  loadData() {
    this.loading_data = true;
    this.spinner.show("spinner");
    const todayString = new Date().toISOString().split('T')[0];
    const paramsDaily: DailySummaryParams = {
        date: todayString
    }
    this.getMonthlySummaryByBrand();
    this.reportService.getDailySummary(paramsDaily).subscribe({
      next: (res: any) => {
        this.dailySummary = res;
        this.spinner.hide('spinner');
        this.loading_data = false
      },
      error: () => {
        this.spinner.hide('spinner');
        this.loading_data = false
      },
    });

    this.reportService.getMonthlySummary(this.paramsMonth).subscribe({
      next: (res: any) => {
        this.dataSummary = res;
        this.spinner.hide('spinner');
        this.loading_data = false
      },
      error: () => {
        this.spinner.hide('spinner');
        this.loading_data = false
        },
    });

    this.reportService.getDailySummaryProduct(this.paramsMonthProduct).subscribe({
      next: (res: any) => {
        this.monthlySummaryProduct = res;
        this.spinner.hide('spinner');
        this.loading_data = false
        this.transferDataSource();
      },
      error: () => {
        this.spinner.hide('spinner');
        this.loading_data = false
      },
    });
  }

  transferDataSource() {
    if (this.monthlySummaryProduct?.data) {
      this.dataSource = this.monthlySummaryProduct.data.filter((monthlySummaryProduct: any) => monthlySummaryProduct.totalUnit !== 0).map((monthlySummaryProduct: any) => ({
      ...monthlySummaryProduct,
      }));
    }
  }

  navigateToOrders() {
    this.router.navigate(['/stockist/orders']);
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const momentDate = moment(normalizedMonthAndYear);
    const ctrlValue = this.date.value ? moment(this.date.value) : moment();
    ctrlValue.month(momentDate.month());
    ctrlValue.year(momentDate.year());
    this.date.setValue(normalizedMonthAndYear);
    datepicker.close();
  }

  newDate() {
    this.date.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      const selectedDate = this.date.value;
      const formattedMonth = moment(selectedDate).format('MM');
      const formattedYear = moment(selectedDate).format('yyyy');
      const monthNumber = parseInt(formattedMonth);
      const yearNumber = parseInt(formattedYear);
      if (!isNaN(monthNumber)) {
        this.paramsMonth.month = monthNumber;
        this.paramsMonth.year = yearNumber;
        this.paramsMonthProduct.month = monthNumber;
        this.paramsMonthProduct.year = yearNumber;
        this.loadData();
      }
    });
  }

  onSortOptionChangeDatatable(selectedOption: any) {
    this.paramsMonthProduct.orderBy = selectedOption.value;
    this.loadData();
  }

  getMonthlySummaryByBrand() {
    this.reportService.getMonthlySummaryByBrand(this.paramsMonth).subscribe((res: MonthlySummaryByBrand[]) => {
      this.brandData = res.filter(brand => brand.totalAmount !== 0 && brand.totalQuantity !== 0); 
    this.sortBrandData();
    });
  }

  getMonthlySummaryByCategory() {
    this.reportService.getMonthlySummaryByCategory(this.paramsMonth).subscribe((res: MonthlySummaryByBrand[]) => {
      this.brandData = res.filter(brand => brand.totalAmount !== 0 && brand.totalQuantity !== 0);
      this.sortBrandData()
    });
  }

  getBrandColor(brand: MonthlySummaryByBrand): string {
    const index = this.brandData.indexOf(brand);
    return this.predefinedColors[index % this.predefinedColors.length];
  }

  onSortOptionChange(selectedOption: any) {
    this.selectedSortOption = selectedOption.value;
    this.sortBrandData();
  }

  onSortOptionTypeChange(selectedOptionByType: any) {
    this.selectedsortingOptionByType = selectedOptionByType.value;
    if (this.selectedsortingOptionByType === 'brand') {
      this.getMonthlySummaryByBrand();
    } else if (this.selectedsortingOptionByType === 'category') {
      this.getMonthlySummaryByCategory();
    }
  }

  sortBrandData() {
    switch (this.selectedSortOption) {
      case 'TotalUnit_desc':
        this.brandData = this.brandData.sort((a, b) => b.totalQuantity - a.totalQuantity);
        this.showByType = "Quantity";
        break;
      case 'TotalAmount_desc':
        this.brandData = this.brandData.sort((a, b) => b.totalAmount - a.totalAmount);
        this.showByType = "Amount";
        break;
      case 'TotalUnit_asc':
        this.brandData = this.brandData.sort((a, b) => a.totalQuantity - b.totalQuantity);
        this.showByType = "Quantity";
        break;
      case 'TotalAmount_asc':
        this.brandData = this.brandData.sort((a, b) => a.totalAmount - b.totalAmount);
        this.showByType = "Amount";
        break;
    }
    this.brandData = [...this.brandData];
  }
}
