/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseOrderItem, PurchaseOrderPromotion } from './preview-order.model';

export interface StockistsResponse {
  items: Stockist[];
  message?: string;
}

export class Stockist {
  id: number;
  name: string;
  nameEN: string;
  logoPathThump: string;
  image_Url: string;

  constructor(data: any) {
    this.id = data?.id || 0;
    this.name = data?.name || '';
    this.nameEN = data?.nameEN || '';
    this.logoPathThump = data?.logoPathThump || '';
    this.image_Url = data?.image_Url || '';
  }
}

export interface StockistInfo {
    stockistId: number;
    stockistName: string;
    stockistAddress: string;
}

export class StockistProfile {
    id: number;
    name: string;
    logoPath: string;

    constructor(data: StockistProfile) {
        this.id = data.id;
        this.name = data.name;
        this.logoPath = data.logoPath;
    }
}

export class OrderAmount {
    amount: number;

    constructor(data: OrderAmount) {
        this.amount = data.amount;
    }
}

export interface IStockistPurchaseOrder {
    id: string;
    status: string;
    createdAt: string;
    stockistName: string;
    netAmount: number;
    retailerName: string;
    totalQuantity: number;
    deliveryFee: number;
    officeDays: string;
    actionBy: string;
}

export class StockistPurchaseOrder {
    id: string;
    status: string;
    createdAt: string;
    stockistName: string;
    netAmount: number;
    retailerName: string;
    totalQuantity: number;
    deliveryFee: number;
    officeDays: string;

    constructor(data?: IStockistPurchaseOrder) {
        this.id = data?.id || '';
        this.status = data?.status || '';
        this.createdAt = data?.createdAt || '';
        this.stockistName = data?.stockistName || '';
        this.netAmount = data?.netAmount || 0.00;
        this.retailerName = data?.retailerName || '';
        this.totalQuantity = data?.totalQuantity || 0;
        this.deliveryFee = data?.deliveryFee || 0.00;
        this.officeDays = data?.officeDays || '';
    }
}

export type StockistPurchaseOrderList = IStockistPurchaseOrder[];

export class StockistPurchaseOrderDetail {
    stockistId: number;
    productId: number;
    id: string;
    status: string;
    stockistName: string | null;
    retailerName: string;
    retailerAddress: string;
    retailerProvince: string | null;
    retailerDistrict: string | null;
    retailerSubdistrict: string | null;
    retailerPostCode: string | null;
    billingAddress: string;
    billingProvince: string | null;
    billingDistrict: string | null;
    billingSubdistrict: string | null;
    billingPostCode: string | null;
    createdAt: string;
    cancelledDate: string;
    cancelRequestedDate: string;
    confirmedDate: string;
    deliveredDate: string;
    cancelReasonOrder: string;
    cancelRejectedDate: string | null;
    amount: number;
    discount: number;
    shippingCost: number;
    netAmount: number;
    taxid: string;
    mobilePhoneNumber: string;
    secondaryPhoneNumber: string | null;
    cancelRequest: boolean;
    refOrderId: string | null;
    deliveryDate: string;
    cancelBy: string
    officeDays: string;
    officeTime: string;
    actionBy: string;
    items: PurchaseOrderItem[];
    couponDiscount: number;
    promotionDiscount: number;
    latitude: number;
    longitude: number;
    promotions: PurchaseOrderPromotion[]

    constructor(data?: any) {
        this.stockistId = data.stockistId || '';
        this.productId = data.productId || '';
        this.id = data.id || '';
        this.status = data.status || '';
        this.stockistName = data.stockistName || null;
        this.retailerName = data.retailerName || '';
        this.retailerAddress = data.retailerAddress || '';
        this.retailerProvince = data.retailerProvince || null;
        this.retailerDistrict = data.retailerDistrict || null;
        this.retailerSubdistrict = data.retailerSubdistrict || null;
        this.retailerPostCode = data.retailerPostCode || null;
        this.billingAddress = data.billingAddress || '';
        this.billingProvince = data.billingProvince || null;
        this.billingDistrict = data.billingDistrict || null;
        this.billingSubdistrict = data.billingSubdistrict || null;
        this.billingPostCode = data.billingPostCode || null;
        this.createdAt = data.createdAt || '';
        this.cancelledDate = data.cancelledDate || '';
        this.cancelRequestedDate = data.cancelRequestedDate || '';
        this.confirmedDate = data.confirmedDate || '';
        this.deliveredDate = data.deliveredDate || '';
        this.cancelReasonOrder = data.cancelReasonOrder || '';
        this.cancelRejectedDate = data.cancelRejectedDate || null;
        this.amount = data.amount || 0;
        this.discount = data.discount || 0;
        this.couponDiscount = data.couponDiscount || 0;
        this.promotionDiscount = data.promotionDiscount || 0;
        this.shippingCost = data.shippingCost || 0;
        this.netAmount = data.netAmount || 0;
        this.taxid = data.taxid || '';
        this.mobilePhoneNumber = data.mobilePhoneNumber || '';
        this.secondaryPhoneNumber = data.secondaryPhoneNumber || null;
        this.cancelRequest = data.cancelRequest || false;
        this.refOrderId = data.refOrderId || null;
        this.deliveryDate = data.deliveryDate || '';
        this.cancelBy = data.cancelBy || '';
        this.officeDays = data.officeDays || '';
        this.officeTime = data.officeTime || '';
        this.actionBy = data.actionBy || '';
        this.items = data?.items || [];
        this.couponDiscount = data?.couponDiscount || 0;
        this.promotionDiscount = data?.promotionDiscount || 0;
        this.promotions = data?.promotions || [];
        this.latitude = data.latitude || null
        this.longitude = data.longitude || null
    }
}

export interface StockistPurchaseOrderParams {
    status?: string;
    actionBy?: string;
    limit?: number;
    offset?: number;
    page?: number;
    orderBy?: string | "CreatedAt_asc" | "CreatedAt_desc"
}

export interface ICheckManagedShelf {
    id: number
    isManageShelf: boolean
}

export interface IStockistOrderStatusTotal {
    totalPendingOrder?: string
    totalConfirmOrder?: string
    totalCancelRequestedOrder?: string
}

export class StockistOrderStatusTotal {
    totalPendingOrder?: string
    totalConfirmOrder?: string
    totalCancelRequestedOrder?: string

    constructor(data?: IStockistOrderStatusTotal) {
        this.totalPendingOrder = data?.totalPendingOrder;
        this.totalConfirmOrder = data?.totalConfirmOrder;
        this.totalCancelRequestedOrder = data?.totalCancelRequestedOrder;
    }
}
