import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {
  @Input() placeholder = '';
  @Output() searchChange = new EventEmitter<string>();
  value = '';

  onSearch(): void {
    this.searchChange.emit(this.value);
  }

  handleSearchClick(): void {
    this.onSearch();
  }

  onClearSearchValue(): void {
    this.value = '';
    this.onSearch(); // Clear search query
  }
}
