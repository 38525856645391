import { Component } from '@angular/core';
import { TranslationService } from '@modules/shared/i18n';
import { TranslateService } from '@ngx-translate/core';
import { locale as enLang } from '../../modules/shared/i18n/vocabs/en';
import { locale as thLang } from '../../modules/shared/i18n/vocabs/th';

@Component({
    selector: 'app-layout-stockist',
    templateUrl: './layout-stockist.component.html',
    styleUrls: ['./layout-stockist.component.scss'],
})
export class LayoutStockistComponent {
    constructor(
        private translationService: TranslationService,
        private translate: TranslateService
    ) {
        // register translations
        this.translationService.loadTranslations(enLang, thLang);
    }
}
