import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AuthUser,
    EmailVerify,
    OTPVerify,
    OTPresponse,
    PhoneVerify,
    credential,
} from '@core/models/auth/auth-user.model';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import { UserServices } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private authUser: UserServices,
        private jwtHelper: JwtHelperService,
        private router: Router
    ) {}

    api = environment.stockistAPI;

    stockistLogin(username: string, password: string): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/stockist/login`,
            {
                username: username,
                password: password,
            }
        );
    }

    setToken(token: string, refreshtoken: string) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshtoken');
        localStorage.setItem('token', token);
        localStorage.setItem('refreshtoken', refreshtoken);
        const jwtDecode = this.jwtHelper.decodeToken(token);
        const user = {
            profile: {
                type:
                    jwtDecode.groups && jwtDecode.groups.length > 0
                        ? jwtDecode.groups[0].toUpperCase()
                        : 'UNKNOWN',
                email: jwtDecode.email,
            },
        };
        localStorage.setItem('user', JSON.stringify(user));
    }

    permissionAuth() {
        const token = localStorage.getItem('token');
        if (token) {
            const jwtDecode = this.jwtHelper.decodeToken(token);
            localStorage.setItem('user', jwtDecode);

            return jwtDecode;
        } else {
            console.error('no token');
        }
    }

    refreshToken(): Observable<credential> {
        const refreshToken = localStorage.getItem('refreshtoken');
        return this.http.post<credential>(`${this.api}/stockist/refresh`, {
            refreshToken,
        });
    }

    async refreshTokenAsync(): Promise<credential> {
        return lastValueFrom(this.refreshToken());
    }

    isTokenExpired(): Observable<boolean> {
        const token = localStorage.getItem('token');
        return of(this.jwtHelper.isTokenExpired(token));
    }

    emailVerify(email: string): Observable<EmailVerify> {
        return this.http.post<EmailVerify>(
            `${environment.apiLoginUrl}/stockist/verify-email`,
            {
                email,
            }
        );
    }

    ///////////////////otp
    requestOTP(email: string): Observable<OTPresponse> {
        return this.http.post<OTPresponse>(
            `${environment.apiLoginUrl}/stockist/request-otp`,
            {
                mobilePhoneNumber: email,
            }
        );
    }

    VerifyOTP(otp: string, refCode: string): Observable<OTPVerify> {
        return this.http.post<OTPVerify>(
            `${environment.apiLoginUrl}/stockist/verify-otp`,
            {
                refCode: refCode,
                otp: otp,
            }
        );
    }

    logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshtoken');
        this.router.navigate(['/auth']);
    }

    onBoarding(token: string, newPassword: string): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/stockist/onboard`,
            {
                token: token,
                newPassword: newPassword,
            }
        );
    }

    /////set-password
    setNewPassword(token: string, newPassword: string): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/stockist/set-password`,
            {
                token: token,
                newPassword: newPassword,
            }
        );
    }

    getNewAccessToken(refreshToken: string): Observable<APIResponse<AuthUser>> {
        return this.http
            .put<APIResponse<AuthUser>>(
                `${environment.apiUrl}/authorization-service/v0/token`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                }
            )
            .pipe(
                map((res) => {
                    this.authUser.setUser(res.data);
                    return res;
                })
            );
    }

    loginretailer(
        countryCode: string,
        phoneNumber: string,
        password: string
    ): Observable<APIResponse<AuthUser>> {
        return this.http
            .post<APIResponse<AuthUser>>(
                `${environment.apiUrl}/authorization-service/v0/sign-in/phoneNumber`, // Mock up
                {
                    countryCode,
                    phoneNumber,
                    password,
                }
            )
            .pipe(
                map((res) => {
                    this.authUser.setUser(res.data);
                    return res;
                })
            );
    }

    phoneVerify(mobilePhoneNumber: string): Observable<PhoneVerify> {
        return this.http.post<PhoneVerify>(
            `${environment.apiLoginUrl}/stockist/verify-phone`,
            {
                mobilePhoneNumber,
            }
        );
    }
}
