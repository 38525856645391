import { Component, Input } from '@angular/core';
import { PromotionGiveAway } from '@core/models/stockist/preview-order.model';

@Component({
  selector: 'app-show-gift-list',
  templateUrl: './show-gift-list.component.html',
  styleUrls: ['./show-gift-list.component.scss']
})
export class ShowGiftListComponent {
  @Input() gift: PromotionGiveAway = new PromotionGiveAway({});
  @Input() quatity = 0;
}
