import { NgSelectModule } from '@ng-select/ng-select';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputPasswordComponent } from './input-password/input-password.component';
import { ReasonCancellationModalComponent } from './reason-cancellation-modal/reason-cancellation-modal.component';
import { DatatableComponent } from './datatable/datatable.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ChartsComponent } from './charts/charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { InputSearchComponent } from './input-search/input-search.component';
import { ModalEditShelfComponent } from './modal-edit-shelf/modal-edit-shelf.component';
import { InputPriceComponent } from './input-price/input-price.component';
import { InputMaxMinComponent } from './input-max-min/input-max-min.component';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    schemas: [NO_ERRORS_SCHEMA],
    declarations: [
        InputComponent,
        InputPasswordComponent,
        SelectComponent,
        ReasonCancellationModalComponent,
        ModalEditShelfComponent,
        DatatableComponent,
        ChartsComponent,
        SelectDropdownComponent,
        InputSearchComponent,
        InputPriceComponent,
        InputMaxMinComponent

    ],
    imports: [
        NgSelectModule,
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        NgApexchartsModule,
        NgxMaskModule.forRoot(maskConfig),
    ],
    exports: [
        InputComponent,
        InputPasswordComponent,
        SelectComponent,
        SelectDropdownComponent,
        ReasonCancellationModalComponent,
        ModalEditShelfComponent,
        DatatableComponent,
        ChartsComponent,
        InputSearchComponent,
        InputPriceComponent,
        InputMaxMinComponent

    ],
})
export class SharedModule { }
