import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    NotificationList,
    NotificationParams,
} from '@core/models/stockist/notification.model';
import { NotificationService } from '@core/services/stockist/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-my-notification',
    templateUrl: './my-notification.component.html',
    styleUrls: ['./my-notification.component.scss'],
})
export class MyNotificationComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private translate: TranslateService
    ) {}

    notifications: NotificationList = [];
    // stockistPurchaseOrders: StockistPurchaseOrderList = [];
    days: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    colors: string[] = [
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
    ];

    totalOrder = 0;
    totalOrderPage = 0
    loading_data = false;
    activeType = 'Order';
    currentLang!: string;

    params: NotificationParams = {
        type: '',
        limit: 10,
        offset: 0,
        page: 1,
    };

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.getNotifications();
    }

    isActive(type: string): boolean {
        return this.activeType === type;
    }

    getNotifications() {
        if(this.notifications.length == 0){
            this.loading_data = true;
        }

        this.notificationService.getNotifications(this.params).subscribe((res) => {
            this.totalOrder = res.total;
            this.totalOrderPage = res.totalPage;
            this.notifications.push(...res.data)

            this.loading_data = false;
        });
    }

    isDayActive(day: string, officeDays: string): boolean {
        return new Set(officeDays.split('|')).has(day);
    }

    goToOrdersPage() {
        this.router.navigate(['stockist/orders']);
    }

    onSelectNotification(notificationId: number, orderId?: string) {
        window.localStorage.setItem('page', 'notification');
        this.notificationService.setReadNotification(notificationId).subscribe(() => {
            const targetPath = `/stockist/order/${orderId}`;
            // this.router.navigate([targetPath]);
            this.router.navigate([targetPath]).then(() => {
              window.location.reload();
            });
        });
    }


    onScroll() {
      if (this.params.page && this.params.page < this.totalOrderPage) {
        this.params.page += 1;
        this.getNotifications();
      }
    }
}
