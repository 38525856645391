import { Component, Input } from '@angular/core';
import { StockistPurchaseOrderDetail } from '@core/models/stockist/stockist.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ship-to-address',
    templateUrl: './ship-to-address.component.html',
    styleUrls: ['./ship-to-address.component.scss'],
})
export class ShipToAddressComponent {
    @Input() stockistOrder!: StockistPurchaseOrderDetail;
    @Input() isShowLocation = false;

    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang('th');
     }
    mapImage = 'assets/images/map-paper.png';
    mapIcon = 'assets/images/map-icon.png'

    navigateToMap(latitude: number, longitude: number) {
        const url = `https://maps.google.com/?q=${latitude},${longitude}`;
        window.open(url, '_blank');
    }
}
