<div class="layer-notify">
    <nav class="navigate-bar">
        <div class="back-container" (click)="goToOrdersPage()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "MY_NOTIFICATION.GO_BACK" | translate }}</label>
        </div>
    </nav>

    <div *ngIf="!loading_data && this.notifications.length > 0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <div *ngFor="let status of [
        { notifications: notifications ,page : 1, text: 'Order'},
        ]; let i = index">
            <ng-container *ngIf="activeType === status.text">
                <!-- Open loop for noti list -->
                <ng-container *ngFor="let notify of status.notifications">
                    <div class="d-flex flex-column mt-3 mx-3 notifyList" aria-hidden="true"
                        (click)="onSelectNotification(notify.id, notify.orderId)">
                        <div class="row">
                            <!-- Title -->
                            <div class="col-11 text-title">
                                <label> {{ currentLang === 'th' ? notify.title : notify.titleEn }} </label>
                            </div>
                            <!-- Unread Alert -->
                            <div *ngIf="!notify.readAt" class="col-1" align="right">
                                <img class="" src="assets/icons/Alert.svg" width="12px" height="12px" alt="" />
                            </div>
                        </div>

                        <div class="mt-2 mb-1 order-number">
                            <label>{{ "MY_NOTIFICATION.ORDER_ID" | translate }}&nbsp;{{notify.orderId}} </label>
                        </div>

                        <div class="row text-detail">
                            <div class="col-12">
                                <label>{{ currentLang === 'th' ? notify.description : notify.descriptionEn }}</label>
                            </div>
                        </div>

                        <div class="row mt-3 text-date">
                            <div class="col-12">
                                <label>{{ notify.createdAt | displayDate }}</label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div *ngIf="!loading_data && this.notifications.length === 0" class="empty-order">
        <div class="content">
            <img src="../../../../../../../assets/images/Empty_Notification_Image.png" alt="Empty history image">
            <p class="text-empty">{{ "MY_NOTIFICATION.NO_NOTIFICATION" | translate }}</p>
            <p class="text-empty-sup">{{ "MY_NOTIFICATION.NEW_NOTIFICATION" | translate }}</p>
        </div>
    </div>
</div>
