import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    StockistOrderStatusBar,
    StockistOrderStatus,
    CancelStatus,
} from '@core/models/stockist/stockist-enum';
import {
    IStockistPurchaseOrder,
    OrderAmount,
    StockistProfile,
    StockistPurchaseOrderList,
    StockistPurchaseOrderParams,
} from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-orders',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements OnInit {
    orderStatuses = Object.values(StockistOrderStatusBar);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statusTranslations: { [key: string]: any } = {
        All: { en: 'All', th: this.translate.instant('RETAILER_STATUS.ALL') },
        Pending: { en: 'Pending', th: this.translate.instant('ORDER_HISTORY.STATUS_PENDING') },
        Confirmed: { en: 'Confirmed', th: this.translate.instant('ORDER_HISTORY.STATUS_CONFIRMED') },
        CancelRequested: { en: 'CancelRequested', th: this.translate.instant('ORDER_HISTORY.STATUS_CANCEL_REQUESTED') },
        Cancelled: { en: 'Cancelled', th: this.translate.instant('RETAILER_STATUS.CANCELLED') },
        Delivered: { en: 'Delivered', th: this.translate.instant('ORDER_HISTORY.STATUS_DELIVERED') },
    };

    tabSelected = CancelStatus.RETAILER_CANCEL;

    cancel_statuses = [
        { value: CancelStatus.RETAILER_CANCEL, name: this.translate.instant('ORDER_HISTORY.STATUS_CANCEL_BY_RETAILER') },
        { value: CancelStatus.STOCKIST_CANCEL, name: this.translate.instant('ORDER_HISTORY.STATUS_CANCEL_BY_STOCKIST') },
    ];

    pending: StockistPurchaseOrderList = [];
    confirmed: StockistPurchaseOrderList = [];
    cancelled: StockistPurchaseOrderList = [];
    cancelrequested: StockistPurchaseOrderList = [];
    delivered: StockistPurchaseOrderList = [];
    deprecated: StockistPurchaseOrderList = [];
    all: StockistPurchaseOrderList = [];
    stockistProfile: StockistProfile = { id: -1, name: 'ร้าน', logoPath: '' };
    orderAmount: OrderAmount = { amount: 0 };
    stockistPurchaseOrders: StockistPurchaseOrderList = [];
    days: string[] = []
    thDays: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    enDays: string[] = [
        'M',
        'T',
        'W',
        'Th',
        'F',
        'S',
        'Su',
    ]
    colors: string[] = [
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
    ];

    totalOrder = 0;
    loading_data = true
    totalPage = 0;

    params: StockistPurchaseOrderParams = {
        status: '',
        actionBy: '',
        limit: 10,
        offset: 0,
        page: 1,
    };
    activeStatus = 'All';
    haveData = false

    constructor(
        private router: Router,
        private userService: UserService,
        private stockistService: StockistService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
    ) {
        this.route.params.subscribe((params) => {
            this.selectTab(params['selectedTab']);
        });
    }

    selectTab(tabText: string) {
        if (tabText) {
            this.activeStatus = tabText;
        } else {
            this.activeStatus = 'All';
        }
    }

    ngOnInit(): void {
        const isThai = this.translate.currentLang === 'th';
        this.days = isThai ? this.thDays : this.enDays
        this.getPurchaseOrders();
    }

    setStatus(status: string) {
        this.haveData = false
        this.loading_data = true;
        this.params.limit = 10;
        this.params.page = 1;
        const statusEn = this.statusTranslations[status];
        this.params.status = statusEn.en;
        if (this.params.status === 'All') {
            this.params.status = '';
        }
        if (this.params.status != 'Cancelled') {
            this.params.actionBy = '';
        } else {
            this.tabSelected = CancelStatus.RETAILER_CANCEL;
            this.params.actionBy = 'Retailer';
        }
        this.activeStatus = status;
        this.all = []
        this.pending = []
        this.confirmed = []
        this.cancelrequested = []
        this.cancelled = []
        this.delivered = []
        this.totalOrder = 0
        this.getPurchaseOrders();
    }
    getStatusTranslation(status: string, language: string): string {
        return this.statusTranslations[status][language];
    }

    isActive(status: string): boolean {
        return this.activeStatus === status;
    }

    // getDateOrder(date: string): string {
    //     return APP_DATE_FORMATS_TH(date);
    // }

    getStockistInfo() {
        this.stockistService.getAmountMonth().subscribe((res) => {
            this.orderAmount = res.data;
        });

        this.userService.getStockistProfile().subscribe((res) => {
            this.stockistProfile = res;
        });
    }

    getPurchaseOrders() {
        this.spinner.show("spinner");
        this.params.status = this.activeStatus === 'All' ? '' : this.activeStatus;
        this.params.orderBy = "CreatedAt_desc"

        this.stockistService.getPurchaseOrders(this.params).subscribe({
            next: (res) => {
                this.spinner.hide('spinner');
                this.totalOrder = res.total;
                this.totalPage = res.totalPage
                if (res.total > 0) {
                    this.haveData = true
                }
                switch (this.params.status?.toLowerCase()) {
                    case StockistOrderStatus.PENDING.toLowerCase():
                        this.pending.push(...res.data)
                        break;
                    case StockistOrderStatus.CONFIRMED.toLowerCase():
                        this.confirmed.push(...res.data)
                        break;
                    case StockistOrderStatus.CANCELREQUESTED.toLowerCase():
                        this.cancelrequested.push(...res.data)
                        break;
                    case StockistOrderStatus.CANCELLED.toLowerCase():
                        this.cancelled.push(...res.data)
                        break;
                    case StockistOrderStatus.DELIVERED.toLowerCase():
                        this.delivered.push(...res.data)
                        break;
                    case StockistOrderStatus.DEPRECATED.toLowerCase():
                        this.deprecated.push(...res.data)
                        break;
                    default:
                        this.all.push(...res.data)
                        break;
                }
                this.loading_data = false
            }, error: (err) => {
                console.error(err)
                this.haveData = false
                this.loading_data = false
            }
        });
    }
    getStatusLabel(status: string): string {
        switch (status.toUpperCase()) {
            case StockistOrderStatus.PENDING:
                return 'รอยืนยัน';
            case StockistOrderStatus.CONFIRMED:
                return 'ยืนยันแล้ว';
            case StockistOrderStatus.CANCELREQUESTED:
                return 'ขอยกเลิก';
            case StockistOrderStatus.CANCELLED:
                return 'ยกเลิก';
            case StockistOrderStatus.DELIVERED:
                return 'จัดส่งสำเร็จ';
            case StockistOrderStatus.DEPRECATED:
                return 'ยกเลิก';
            default:
                return 'Unknown status';
        }
    }

    // dayColor(arr: any[]) {
    //   case
    //   console.log("Array processed:", arr);
    // }

    isDayActive(day: string, officeDays: string | undefined): boolean {

        if (!officeDays) {
          return false;
        }
        let d = ''
        if (day === 'Su' || day === 'อา'){
          d = 'อา'
        }
        if (day === 'M' || day === 'จ'){
          d = 'จ'
        }
        if (day === 'T' || day === 'อ'){
          d = 'อ'
        }
        if (day === 'W' || day === 'พ'){
          d = 'พ'
        }
        if (day === 'Th' || day === 'พฤ'){
          d = 'พฤ'
        }
        if (day === 'F' || day === 'ศ'){
          d = 'ศ'
        }
        if (day === 'S' || day === 'ส'){
          d = 'ส'
        }
        return new Set(officeDays.split('|')).has(d);
    }

    navigateToOrder(order: IStockistPurchaseOrder) {
        window.localStorage.setItem('page', 'history');
        this.router.navigate(['/stockist/order', order.id]);
    }

    navigateToOrders() {
        this.router.navigate(['/stockist/orders']);
    }

    onChangeTab(value: CancelStatus) {
        this.haveData = false
        this.loading_data = true;
        this.params.limit = 10;
        this.params.page = 1;
        this.tabSelected = value;
        this.totalOrder = 0;
        if (this.tabSelected === 'RetailerCancel') {
            this.params.actionBy = 'Retailer';
        } else if (this.tabSelected === 'StockistCancel') {
            this.params.actionBy = 'Stockist';
        }
        this.cancelled = []
        this.getPurchaseOrders();
    }

    onScroll() {
        if (this.params.page && this.params.page < this.totalPage) {
            this.params.page += 1;
            this.getPurchaseOrders();
        }
    }
}
