import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ship-date',
    templateUrl: './ship-date.component.html',
    styleUrls: ['./ship-date.component.scss'],
})
export class ShipDateComponent {
    @Input() deliveryDate = '';
    @Input() status = '';


    mapImage = 'assets/images/map.png';
    mapIcon = 'assets/images/map-icon.png'
}
