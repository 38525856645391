/* eslint-disable @typescript-eslint/no-explicit-any */
// import { UserService } from '@core/services/auth/user.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang('th');
    }

    loadTranslations(...args: any[]): void {
        args.forEach((locale) => {
            this.translate.setTranslation(locale.lang, locale.data, true);
        });
    }

    getLanguage(): string {
        // return this.translate.currentLang || 'th';
        return 'th';
    }
}
