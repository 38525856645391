/* eslint-disable @typescript-eslint/no-explicit-any */

let env: any = {};

declare const CONFIG: { string: string };
if (typeof CONFIG === 'undefined') {
  env = {
    secret: 'aura',
    secretToken: 'auratoken',
    apiUrl: 'https://stockist.aura-dev.site',
    apiUrlLine: 'https://stockist.aura-dev.site/api/line',
    apiCustomerUrl: 'https://stockist.aura-dev.site/customer',
    apiLoginUrl: 'https://stockist.aura-dev.site/apix/customer',
    stockistAuth: "https://stockist.aura-dev.site/auth/customer",
    promotionApi: "https://stockist.aura-dev.site/api/promotion",
    stockistAPI: "https://stockist.aura-dev.site/api/customer",
    apiAdminUrl: 'https://stockist.aura-dev.site/api/customer',
    apiNotificationUrl: 'https://stockist.aura-dev.site/api/notification',
    apiProductUrl: 'https://stockist.aura-dev.site/api/product',
    zendeskUrl: 'https://static.zdassets.com/ekr/snippet.js?key=8ee3894f-2a93-4b80-a189-f05c7ac451cc',
    // apiUrl: 'http://localhost:5240',
    name: 'local environment',
    env: 'local',
  };
} else {
  env = CONFIG;
}

export const environment = {
  ...env,
  firebaseConfig: {
    apiKey: 'AIzaSyDCpqEhy34qKd0QZdYZHHRi_dSwN9KdPWY',
    authDomain: 'tnt-aura-dev-8cb6e.firebaseapp.com',
    projectId: 'tnt-aura-dev-8cb6e',
    storageBucket: 'tnt-aura-dev-8cb6e.appspot.com',
    messagingSenderId: '925414639265',
    appId: '1:925414639265:web:c4af039a8bcaaeaf5d52e0',
  },
};
