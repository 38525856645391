import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductParams } from './../../../../../../core/models/stockist/product.model';
import { ProductStockist } from '@core/models/stockist/product.model';
import { AzureBlobService } from '@core/services/stockist/azure-blob.service';
import { ProductService } from '@core/services/retailer/product.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface FilterEvent {
    params: ProductParams;
    totalSelected: number;
}
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
    productList: ProductStockist[] = [];
    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    params: ProductParams = {
        limit: 50,
        page: 1,
        datatable: true,
    };
    showNotFoundIcon = false;
    isOpenFilterModal = false;
    hasMoreData = true;
    total = 0;
    filterTotal = 0;

    constructor(
        private route: Router,
        private productService: ProductService,
        private azureBlobService: AzureBlobService,
        private translate: TranslateService
    ) { }

    search(query: string): void {
        this.params = {
            ...this.params,
            limit: 50,
            page: 1,
            datatable: true,
            keyword: query,
        };
        this.productList = [];
        this.handleSearch();
    }

    handleSearch() {
        this.productService
            .getProductsAvailableWithDataTable(this.params)
            .subscribe((res) => {
                this.total = res.total;
                if (Array.isArray(res.data)) {
                    if (res.data.length === 0) {
                        this.hasMoreData = false;
                    } else {
                        const newProducts = res.data
                        const existingIds = this.productList.map(
                            (item) => item.id
                        );
                        const uniqueNewProducts = newProducts.filter(
                            (item) => !existingIds.includes(item.id)
                        );
                        this.productList.push(...uniqueNewProducts);
                    }
                    this.showNotFoundIcon = this.productList.length === 0;
                }
            });
    }

    navigateBack(): void {
        this.route.navigate(['/all-product']);
    }

    showProductList(): boolean {
        return this.productList.length > 0;
    }

    showNotFoundMessage(): boolean {
        return this.showNotFoundIcon && !this.showProductList();
    }

    openFilterModal() {
        this.isOpenFilterModal = true;
    }

    filterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keyword = this.params.keyword;
        this.params = {
            keyword,
            ...filterEvent.params,
        };
        this.productList = [];
        this.hasMoreData = true;
        this.handleSearch();
    }

    onCloseFilterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keyword = this.params.keyword;
        this.params = {
            keyword,
            ...filterEvent.params,
        };
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight &&
            this.hasMoreData &&
            this.params?.page !== undefined
        ) {
            this.params.page++;
            this.handleSearch();
        }
    }
}
