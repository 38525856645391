import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ListNotificationResponse, NotificationParams, UnreadNotification } from "@core/models/stockist/notification.model";
import { environment } from "@environments/environment";
import { ClearParams } from "@modules/shared/clear-params";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class NotificationService {
    basePath = `${environment.apiNotificationUrl}`;
    constructor(private http: HttpClient) {}

    getNotifications( params: NotificationParams ): Observable<ListNotificationResponse> {
        return this.http.get<ListNotificationResponse>(
            `${this.basePath}/notifications`,
            {
                params: ClearParams(params),
            }
        );
    }

    setReadNotification( id: number ): Observable<null> {
        return this.http.patch<null>( `${this.basePath}/notifications/${id}/read`, null );
    }

    getNotificationCount(): Observable<UnreadNotification> {
        return this.http.get<UnreadNotification>(`${this.basePath}/notifications/unread/count`);
    }
}