import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { StockistOrderStatus } from '@core/models/stockist/stockist-enum';
import { StockistPurchaseOrderList, StockistPurchaseOrderParams } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';


@Component({
  selector: 'app-view-all-orders',
  templateUrl: './view-all-orders.component.html',
  styleUrls: ['./view-all-orders.component.scss']
})

export class ViewAllOrdersComponent implements OnInit {

  stockistPurchaseOrders: StockistPurchaseOrderList = []
  selectedTab = 2;
  pending: StockistPurchaseOrderList = []
  confirmed: StockistPurchaseOrderList = []
  cancelled: StockistPurchaseOrderList = []
  cancelrequested: StockistPurchaseOrderList = []
  delivered: StockistPurchaseOrderList = [];
  deprecated: StockistPurchaseOrderList = [];
  params: StockistPurchaseOrderParams = {
    status: StockistOrderStatus.PENDING,
    limit: 10,
    page: 1,
  };
  constructor(
    private router: Router,
    private stockistService: StockistService,
    private route: ActivatedRoute
    ) {
      this.route.params.subscribe(params => {
        this.selectTab(parseInt(params['selectedTab']));

        // Do something with the parameter value
      });
    }


  selectTab(tabNumber: number) {
    // console.log("select : "+this.selectedTab);
    this.selectedTab = tabNumber;
  }

  ngOnInit(): void {
    this.getPurchaseOrders()
    this.getPendingOrders()
    this.getConfirmedOrders()
    this.getCancelledOrders()
    this.getCancellingOrders()
    this.getDeliveredOrders()
    this.getDeprecatedOrders()
  }

  getPurchaseOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe((res) => {
      this.stockistPurchaseOrders = res.data;
    });
  }

  getPendingOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
        this.pending = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.PENDING);
      }
    );
  }

  getConfirmedOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
        this.confirmed = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.CONFIRMED);
      }
    );
  }

  getCancelledOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
   this.cancelled = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.CANCELLED);
      }
   );
  }

  getCancellingOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
   this.cancelrequested = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.CANCELREQUESTED);
      }
   );
  }
  getDeliveredOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
   this.delivered = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.DELIVERED);
      }
   );
  }
  getDeprecatedOrders() {
    this.stockistService.getPurchaseOrders(this.params).subscribe(
      (res) => {
        this.stockistPurchaseOrders = res.data;
   this.deprecated = this.stockistPurchaseOrders.filter(order => order.status.toUpperCase() === StockistOrderStatus.DEPRECATED);
      }
   );
  }

  getStatusLabel(status: string): string {
    switch (status.toUpperCase()) {
      case StockistOrderStatus.PENDING:
        return 'รอยืนยัน';
      case StockistOrderStatus.CONFIRMED:
        return 'ยืนยันแล้ว';
      case StockistOrderStatus.CANCELREQUESTED:
        return 'ขอยกเลิก';
      case StockistOrderStatus.CANCELLED:
        return 'ยกเลิก';
      case StockistOrderStatus.DELIVERED:
        return 'จัดส่งสำเร็จ';
      case StockistOrderStatus.DEPRECATED:
        return 'ยกเลิก';
      default:
        return 'Unknown status';
    }
  }

  navigateToOrder(orderNumber: string) {
    this.router.navigate(['/stockist/orders', orderNumber]);
  }


}
