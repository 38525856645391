import { Injectable } from '@angular/core';
import { AuthUser } from '@core/models/auth/auth-user.model';

@Injectable({
    providedIn: 'root',
})
export class UserServices {
    setUser(user: AuthUser): void {
        const beforeTokenExpiredFiveMins =
            (user.credential.accessTokenExpiresIn - 300) * 100;
        user.timeout = new Date().getTime() + beforeTokenExpiredFiveMins;
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUser(): AuthUser | null {
        const userJson = localStorage.getItem('user');
        if (userJson) {
            const user: AuthUser = JSON.parse(userJson);
            return user;
        }
        return null;
    }

    clearUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshtoken');
        localStorage.removeItem('local');
        localStorage.clear();
    }
}
